<template>
  <v-container>
    <v-row>
      <v-dialog
        v-model="addNewUserDialog"
        persistent
        max-width="1200px"
        height="1000px"
      >
        <v-form ref="createNewUserForm">
          <v-card>
            <v-card-title class="headline blue darken-4 white--text">
              Add New User
            </v-card-title>
            <v-card-text class="pa-5">
              <div>
                <v-alert
                  dismissible
                  type="success"
                  v-if="addNewUserFlag === true"
                >
                  {{ addNewUserResponseMessage }}
                </v-alert>
                <v-alert
                  dismissible
                  type="error"
                  v-if="addNewUserFlag === false"
                >
                  {{ addNewUserResponseMessage }}
                </v-alert>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="first_name"
                      :rules="firstNameRules"
                      filled
                      label="First Name"
                      type="text"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="last_name"
                      :rules="lastNameRules"
                      filled
                      label="Last Name"
                      type="text"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      filled
                      label="Email"
                      type="text"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="mobile"
                      :rules="mobileRules"
                      filled
                      label="Phone Number"
                      type="text"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="rank"
                      :items="rankArr"
                      :rules="customerRankRules"
                      dense
                      filled
                      label="Customer Rank"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn text dark color="success" @click="createNewUser()">
                    Save
                  </v-btn>
                  <v-btn text dark color="red" @click="closeAddNewUserDialog()">
                    Close
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-dialog>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Users
            </div>
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              <v-btn color="success" @click="showAddNewUserDialog()"
                >Add New User</v-btn
              >
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            User Managment
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @keyup="handelSearch();"
            ></v-text-field>
          </v-card-title>
          <div class="pa-2">
            <v-data-table
              :headers="headers"
              :items="requestLog"
              item-key="id"
              class="elevation-1"
              :options.sync="options"
              :server-items-length="totalUsers"
              :footer-props="{'itemsPerPageOptions':[5, 10, 15]}"
              :loading="isSearching"
            >
              <template v-slot:[`item.detail`]="{ item }">
                <v-btn color="success" @click="openUser(item.ID)">Show</v-btn>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  fab
                  dark
                  small
                  color="red accent-4"
                  @click="deleteUser(item.firebaseID)"
                  ><v-icon dark> mdi-delete </v-icon>
                </v-btn>
                <!--<v-btn fab dark small color="green" @click="exportdata(item)"
                  ><v-icon dark> mdi-microsoft-excel </v-icon></v-btn
                >-->
              </template>
            </v-data-table>
          </div>
        </v-card>

        <v-snackbar v-model="snackbar" :timeout="timeout">
          User deleted successfully.
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import { API_BASE_URL, API_HELIUM_URL } from "../constants";
import moment from "moment";

export default {
  setup() {},
  data() {
    return {
      loading: false,
      options: {},
      totalUsers: 0,
      items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
      rankArr: ["End User", "RDP", "High Valued"],
      message: "",
      amount: 0,
      dialog: false,
      hotspotDetails: [],
      hotspotTotal: [],
      url: "",
      mapurl: "",
      token: JSON.parse(localStorage.getItem("user")),
      start: "",
      today: "",
      search: "",
      uid: "",
      oracleprice: null,
      installation: "",
      requestLog: [],
      maxearn: 0,
      snackbar: false,
      timeout: 4000,
      attrs: "",
      addNewUserDialog: false,
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      rank: "",
      firstNameRules: [
        (v) => !!v || "Please enter first name.",
        (v) => /^[A-Za-z\s]+$/.test(v) || "Please enter valid first name.",
        (v) =>
          (v && v.length <= 10) ||
          "First name must be less than 10 characters.",
      ],
      lastNameRules: [
        (v) => !!v || "Please enter last name.",
        (v) => /^[A-Za-z\s]+$/.test(v) || "Please enter valid last name.",
        (v) =>
          (v && v.length <= 10) || "Last name must be less than 10 characters.",
      ],
      emailRules: [
        (v) => !!v || "Please enter email Id.",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Please enter valid email.",
      ],
      mobileRules: [(v) => !!v || "Please enter mobile number."],
      customerRankRules: [(v) => !!v || "Please select customer rank."],
      addNewUserFlag: null,
      addNewUserResponseMessage: "",
      isSearching: false
    };
  },
  computed: {
    headers() {
      return [
        { text: "Username", value: "name" },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        { text: "Commission %", value: "commission" },
        {
          text: "Created Date",
          value: "createDate",
          sortable: true,
          align: "center",
        },
        {
          text: "Contract Signed?",
          sortable: false,
          value: "signed",
          align: "center",
        },
        {
          text: "Contract Sign Date",
          value: "createdsign",
          align: "center",
          sortable: true,
        },
        {
          text: "Owned Hotspot",
          sortable: true,
          value: "ownedHotspotCount",
          align: "center",
        },
        { text: "Details", value: "detail", sortable: false, align: "center" },
        { text: "Actions", value: "action", sortable: false, align: "center" },
      ];
    },
  },
  beforeMount() {
    //this.getuid();
    var start = new Date();
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var m2 = today.getMonth() - 2;
    var yyyy = today.getFullYear();
    var yyyy2 = today.getFullYear() - 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (m2 < 10) {
      m2 = "0" + m2;
    }
    this.today = yyyy + "-" + mm + "-" + dd;
    this.start = yyyy2 + "-" + m2 + "-" + dd;
    // this.getRequest();
    // this.getOracleValue();
  },
watch: {
  options: {
    handler () {
      this.getUsers();
    },
    deep: true,
  },
},
methods: {
  getUsers() {
    if(this.isSearching === false) {
      this.$root.isLoading = true;
    }
    const { sortBy, sortDesc, page, itemsPerPage } = this.options;
    let sortType = "DESC";
    if (sortDesc.length === 1) {
      if (sortDesc[0]) {
        sortType = "ASC";
      } else {
        sortType = "DESC";
      }
    }
    let skip = 0;
    if (page > 1) {
      skip = itemsPerPage * (page - 1);
    }
    if (this.search != "") {
      skip = 0;
    }
    //Preparing payload object
    let payload = {
      skip: skip,
      limit: itemsPerPage,
      search: this.search,
      orderByColumn: sortBy.length === 1 ? sortBy[0] : "ID",
      orderByType: sortType,
    };
    axios.post(`${API_BASE_URL}/users-pagination`, payload, {
      headers: {
        accept: "application/json",
        "x-access-token": this.token.accessToken,
      },
    })
    .then((resp) => {
      this.totalUsers = resp.data.totalUsers[0].totalUsers;
      let users = resp.data.data;
      users.forEach((obj) => {
        obj["createDate"] = obj["createDate"] ? moment(obj["createDate"]).format("MM-DD-YYYY") : "N/A";
        obj["createdsign"] = obj["createdsign"] ? moment(obj["createdsign"]).format("MM-DD-YYYY h:mm:ss a") : "N/A";
        if(obj["signed"] == null) {
          obj["signed"] = "Temp Whitelist";
        } else if(obj["signed"] == "") {
          obj["signed"] = "No";
        } else if(obj["signed"] == 0) {
          obj["signed"] = "No";
          obj["createdsign"] = "N/A";
        } else if(obj["signed"] == 1) {
          obj["signed"] = "Yes";
        }
      });
      this.requestLog = users;
      this.$root.isLoading = false;
      this.isSearching = false;
    })
    .catch((error) => {
      this.$root.isLoading = false;
      this.isSearching = false;
      console.log(error);
    });
  },
  handelSearch() {
    this.isSearching = true;
    if(this.options.page > 1) {
      this.options.page = 1;
    } else {
      this.getUsers();
    }
  },
  getOracleValue() {
    axios
      .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
        headers: {
          accept: "application/json",
        },
      })
      .then((resp) => {
        this.oracleprice = resp.data;
      });
  },
  showAddNewUserDialog() {
    this.addNewUserDialog = true;
  },
  closeAddNewUserDialog() {
    this.addNewUserFlag = null;
    this.addNewUserResponseMessage = "";
    this.resetCreateNewUserForm();
    this.addNewUserDialog = false;
  },
  resetCreateNewUserForm() {
    this.$refs.createNewUserForm.reset();
  },
  createNewUser() {
    let validateFlag = this.$refs.createNewUserForm.validate();
    if (validateFlag) {
      this.$root.isLoading = true;
      let payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone: this.mobile,
        rank: this.rank,
      };
      axios
        .post(`${API_BASE_URL}/create/new/user`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((response) => {
          this.$root.isLoading = false;
          this.resetCreateNewUserForm();
          this.addNewUserFlag = response.data.status;
          this.addNewUserResponseMessage = response.data.message;
          this.getUsers();
          setTimeout(() => {
            this.closeAddNewUserDialog();
          }, 2000);
        })
        .catch((error) => {
          this.$root.isLoading = false;
          this.resetCreateNewUserForm();
          this.addNewUserFlag = false;
          this.addNewUserResponseMessage = "Opps, Something went wrong.";
          setTimeout(() => {
            this.closeAddNewUserDialog();
          }, 2000);
        });
    }
  },
  sendRequest(amount, message) {
    console.log(this.message);
    axios.post(`${API_BASE_URL}/user/request/${this.uid}`, {
      payment: this.message,
      amount: this.amount,
    });
    this.dialog = false;
    this.getUsers();
  },
  getuid() {
    //this.$cookies.set('uid', this.user.data.uid)
    //this.uid = this.user.data.uid
    axios
      .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
        headers: { accept: "application/json" },
      })
      .then((resp) => {
        this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
      });
  },
  hotspotDet(address, start, today) {
    (this.dialog = false),
      axios
        .get(`${API_HELIUM_URL}/v1/hotspots/${address}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotDetails = resp.data;
          var [year, month, day] = this.hotspotDetails.data.timestamp_added
            .substring(0, 10)
            .split("-");
          this.installation = new Date(year, month, day);
          axios
            .get(
              `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${this.installation
                .toISOString()
                .slice(0, 10)}`,
              {
                headers: { accept: "application/json" },
              }
            )
            .then((resp) => {
              this.hotspotTotal = resp.data;
              this.mapurl = [
                "https://www.openstreetmap.org/export/embed.html?bbox=" +
                  this.hotspotDetails.data.lng +
                  "%2C" +
                  this.hotspotDetails.data.lat +
                  "%2C" +
                  this.hotspotDetails.data.lng +
                  "%2C" +
                  this.hotspotDetails.data.lat +
                  "&layer=mapnik&marker=" +
                  this.hotspotDetails.data.lat +
                  "%2C" +
                  this.hotspotDetails.data.lng,
              ].join("");
              this.maxearn = parseInt(
                ((this.hotspotTotal.data.total / 100) * 20).toFixed(2)
              );
            });
        });
  },
  openUser(userid) {
    this.$router.push({ name: "user", params: { id: userid } });
  },
  getRequestOld() {
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage } = this.options;
    let skip = 0;
    if (page > 1) {
      skip = itemsPerPage * (page - 1);
    }
    if (this.search != "") {
      skip = 0;
    }
    let payload = {
      skip: skip,
      limit: itemsPerPage,
      search: this.search,
    };


        axios.post(`${API_BASE_URL}/users-pagination`, payload, {
                  headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
                })
                .then((resp) => {
                this.totalUsers = resp.data.totalUsers[0].totalUsers;
                let users = resp.data.data;
                console.log(users);
                if (sortBy.length === 1 && sortDesc.length === 1) {

                users = users.sort((a, b) => {
                const sortA = a[sortBy[0]]
                const sortB = b[sortBy[0]]

                if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
                } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
                }
                })
                }


        var requestLog = users;
        users.forEach((obj) => {
          obj["createDate"] = obj["createDate"]
            ? moment(obj["createDate"]).format("MM-DD-YYYY")
            : "N/A";
          obj["createdsign"] = obj["createdsign"]
            ? moment(obj["createdsign"]).format("MM-DD-YYYY h:mm:ss a")
            : "N/A";
          //obj["createDate"] = obj["createDate"].substring(0, 10)
        });
        axios
          .get(`${API_BASE_URL}/usersHotspot`, {
            headers: {
              accept: "application/json",
              "x-access-token": this.token.accessToken,
            },
          })
          .then((resp) => {
            var hotspotOwn = resp.data;
            var i;

            for (i = 0; i < requestLog.length; i++) {
              if (
                requestLog[i]["createdsign"] == "2021-07-06T18:47:22.000Z"
              ) {
                requestLog[i]["createdsign"] = "";
              }
              if (requestLog[i]["signed"] == null) {
                requestLog[i]["signed"] = "Temp Whitelist";
              }
              if (requestLog[i]["signed"] == "") {
                requestLog[i]["signed"] = "No";
              }
              if (requestLog[i]["signed"] == 0) {
                requestLog[i]["signed"] = "No";
                requestLog[i]["createdsign"] = "";
              }
              if (requestLog[i]["signed"] == 1) {
                requestLog[i]["signed"] = "Yes";
              }

              if (
                hotspotOwn.find(
                  ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                )
              ) {
                const result = hotspotOwn.find(
                  ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                );
                requestLog[i].assignHotspot = result.assignHotspot;
              } else {
                requestLog[i].assignHotspot = 0;
                requestLog[i]["createdsign"] = "";
                requestLog[i]["signed"] = "No";
              }
            }
            this.requestLog = requestLog;
          });
                this.requestLog = users;
                this.loading = false;
                })
                .catch((error) => {
                //this.progressBar = false;
                console.log(error);
                this.loading = false;
                });
  },
  getRequestBackup() {
    axios
      .get(`${API_BASE_URL}/users`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        var requestLog = resp.data;
        requestLog.forEach((obj) => {
          obj["createDate"] = obj["createDate"]
            ? moment(obj["createDate"]).format("MM-DD-YYYY")
            : "N/A";
          obj["createdsign"] = obj["createdsign"]
            ? moment(obj["createdsign"]).format("MM-DD-YYYY h:mm:ss a")
            : "N/A";
          //obj["createDate"] = obj["createDate"].substring(0, 10)
        });
        axios
          .get(`${API_BASE_URL}/usersHotspot`, {
            headers: {
              accept: "application/json",
              "x-access-token": this.token.accessToken,
            },
          })
          .then((resp) => {
            var hotspotOwn = resp.data;
            var i;

            for (i = 0; i < requestLog.length; i++) {
              if (
                requestLog[i]["createdsign"] == "2021-07-06T18:47:22.000Z"
              ) {
                requestLog[i]["createdsign"] = "";
              }
              if (requestLog[i]["signed"] == null) {
                requestLog[i]["signed"] = "Temp Whitelist";
              }
              if (requestLog[i]["signed"] == "") {
                requestLog[i]["signed"] = "No";
              }
              if (requestLog[i]["signed"] == 0) {
                requestLog[i]["signed"] = "No";
                requestLog[i]["createdsign"] = "";
              }
              if (requestLog[i]["signed"] == 1) {
                requestLog[i]["signed"] = "Yes";
              }

              if (
                hotspotOwn.find(
                  ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                )
              ) {
                const result = hotspotOwn.find(
                  ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                );
                requestLog[i].assignHotspot = result.assignHotspot;
              } else {
                requestLog[i].assignHotspot = 0;
                requestLog[i]["createdsign"] = "";
                requestLog[i]["signed"] = "No";
              }
            }
            this.requestLog = requestLog;
          });
      });
  },
  filterOnlyCapsText(value, search) {
    return (
      value != null &&
      search != null &&
      typeof value === "string" &&
      value.toString().toLocaleLowerCase().indexOf(search) !== -1
    );
  },
  saveHotspot(address) {
    let payload = { haddress: address };
    let res = axios.put(`${API_BASE_URL}/hotspot/${this.uid}`, payload);
    let data = res.data;
  },
  deleteUser(ID) {
    if (confirm("Do you really want to delete this user?")) {
      axios
        .delete(`${API_BASE_URL}/user/${ID}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.snackbar = true;
          this.getUsers();
        });
    }
  },
},
};
</script>