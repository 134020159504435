<template>
  <v-container>
    <v-row>

      <v-dialog
          v-model="viewDetailsModel"
          persistent
          max-width="1200px"
          height="1000px"
        >
          <v-card>
            <v-card-title class="headline blue darken-4 white--text">
              {{selectedType}}
            </v-card-title>
            <v-card-text class="pa-5">
              <div>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="name"
                        filled
                        label="Name"
                        type="text"
                        readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="email"
                        filled
                        label="Email"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                  </v-col>
                  <v-col v-if="selectedType !== `No Longer Want Hotspot`">
                    <v-text-field
                        v-model="phone"
                        filled
                        label="Phone"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                  </v-col> 
                  <v-col v-if="selectedType === `Update Hotspot Address Request` || selectedType === `Antenna Change Request` || selectedType === `No Longer Want Hotspot`">
                    <v-text-field
                        v-model="hotspotName"
                        filled
                        label="Hotspot Name"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                  </v-col>  
                </v-row>

                <div v-if="selectedType === `Host Support Request`">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="subject"
                        filled
                        label="Subject"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col> 
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="message"
                        filled
                        label="Message"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col> 
                  </v-row>
                </div>
                
                <div v-if="selectedType === `Update Hotspot Address Request`">
                  <v-row >
                    <v-col>
                      <v-text-field
                        v-model="reasonOfAddressChange"
                        filled
                        label="Reason of address Change"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col> 
                  </v-row>
                  <v-card-title>
                    Current Address
                  </v-card-title>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="currentAddressLineOne"
                        filled
                        label="Address Line One"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="currentCity"
                        filled
                        label="City"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="currentCountry"
                        filled
                        label="Country"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="currentState"
                        filled
                        label="State"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="currentZip"
                        filled
                        label="Zip Code"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  
                  <v-card-title>
                    New Address
                  </v-card-title>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="newAddressLineOne"
                        filled
                        label="Address Line One"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="newCity"
                        filled
                        label="City"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="newCountry"
                        filled
                        label="Country"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="newState"
                        filled
                        label="State"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="newZip"
                        filled
                        label="Zip Code"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col v-if="selectedType === `Update Hotspot Address Request`">
                      <v-text-field
                        v-model="newAddressAntennaHeight"
                        filled
                        label="Antenna Height"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  
                </div>

                <div v-if="selectedType === `Antenna Change Request`">
                  <v-row  v-if="selectedType === `Antenna Change Request`">
                    <v-col>
                      <v-text-field
                        v-model="newAntennaGain"
                        filled
                        label="New Antenna Gain"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="newAntennaHeightorFloorNumber"
                        filled
                        label="New Antenna Height Or Floor"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <!-- <v-col>
                      <v-text-field
                        v-model="newAntennaTypeRequested"
                        filled
                        label="New Antenn Type Requested"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col> -->
                  </v-row>
                </div>

                <div v-if="selectedType === `No Longer Want Hotspot`">
                  <v-row >
                    <v-col>
                      <v-text-field
                        v-model="reasonOfReturn"
                        filled
                        label="Reason for return"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col> 
                  </v-row>
                  <v-card-title>
                    Current Address
                  </v-card-title>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="currentAddressLineOne"
                        filled
                        label="Address Line One"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="currentCity"
                        filled
                        label="City"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="currentCountry"
                        filled
                        label="Country"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="currentState"
                        filled
                        label="State"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="currentZip"
                        filled
                        label="Zip Code"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
                
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn text dark color="red" @click="close()">
                    Close
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card-text>
          </v-card>
      </v-dialog>

      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Support Request
            </div>
            <v-spacer></v-spacer>
            
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Support Request Historical Records
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @keyup="handelSearch();"
            ></v-text-field>
          </v-card-title>
          <v-row class="select-wrapper">
              <v-col
                class="d-flex"
                cols="12"
                sm="4"
              >
                <v-select
                  :items="items"
                  label="Request Type"
                  v-model="selectedType"
                  @change="handelRequestType();"
                ></v-select>
              </v-col>
            
          </v-row>

          <div class="pa-2">
            <!-- <v-data-table
              :headers="headers1"
              :items="contactEmails"
              :items-per-page="5"
              class="elevation-1"
              :search="search"
            >
              <template v-slot:[`item.data.name`]="{ item }">
                {{item.data.name}}
              </template>
              <template v-slot:[`item.email`]="{ item }">
                {{item.email}}
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-btn dark small color="success" @click="viewDetails(item)">
                  View
                </v-btn>
              </template>

            </v-data-table> -->
            <v-data-table
              :headers="headers1"
              :items="contactEmails"
              :options.sync="options"
              :server-items-length="totalContactEmails"
              :footer-props="{'itemsPerPageOptions':[5, 10, 15]}"
              class="elevation-1"
            >
              <template v-slot:[`item.data.name`]="{ item }">
                {{item.data.name}}
              </template>
              <template v-slot:[`item.email`]="{ item }">
                {{item.email}}
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-btn dark small color="success" @click="viewDetails(item)">
                  View
                </v-btn>
              </template>
            </v-data-table>
          </div>

        </v-card>
      </v-col>

    
    </v-row>
  </v-container>
</template>





<script>

import axios from "axios";
import { API_BASE_URL, API_HELIUM_URL } from "../constants";
import { mapGetters } from "vuex";

export default {
  setup() {},
  data() {
    return {
      nameRules: [(v) => !!v || "Required"],
      items:[
        {text: 'Host Support Request', value:'Host Support Request'},
        {text: 'Update Hotspot Address Request', value:'Update Hotspot Address Request'},
        {text: 'Antenna Change Request', value:'Antenna Change Request'},
        {text: 'No Longer Want Hotspot', value:'No Longer Want Hotspot'}
      ],
      token: JSON.parse(localStorage.getItem("user")),
      search: "",
      uid: "",
      attrs: '',
      on: '',
      selectedType:'Host Support Request',
      contactEmails:[],
      viewDetailsModel: false,
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      hotspotName: '',
      currentAddressLineOne: '',
      currentCity: '',
      currentCountry: '',
      currentState: '',
      currentZip: '',
      newAddressLineOne: '',
      newCity: '',
      newCountry: '',
      newState: '',
      newZip: '',
      newAddressAntennaHeight: '',
      reasonOfAddressChange: '',
      newAntennaGain: '',
      newAntennaHeightorFloorNumber: '',
      newAntennaTypeRequested: '',
      reasonOfReturn: '',
      options: {},
      totalContactEmails: 0,
    };
  },
  computed: {
    headers1() {
      return[
          { text: 'Name',value:'name'},
          { text: 'Email', value: 'email' },
          // { text: 'Type of Email', value: 'type', filter: this.nameFilter, sortable: false },
          { text: 'Type of Email', value: 'type', sortable: false },
          { text: 'Action', value: 'action', sortable: false},
        ]
    }
  },
  beforeMount() {
    //this.getuid();
    //this.getContactUsEmails();
  },
  watch: {
    options: {
      handler () {
        this.getContactUsEmails();
      },
      deep: true,
    },
  },
  methods: {
    // nameFilter(value) {
    //   if (!this.selectedType) {
    //     return true;
    //   }
    //   return value === this.selectedType
    // },
    close() {
      this.viewDetailsModel = false;
    },
    viewDetails(item) {
      console.log(item);
      this.name = item.name;
      this.email = item.email;
      this.phone = item.phone;

      if(this.selectedType === "Host Support Request") {
        this.subject = item.subject;
        this.message = item.message;
      }

      if(this.selectedType === "Antenna Change Request") {
        this.hotspotName = item.hotspotName;
        this.newAntennaGain = item.newAntennaGain;
        this.newAntennaHeightorFloorNumber = item.newAntennaHeightorFloorNumber;
        this.newAntennaTypeRequested = item.newAntennaTypeRequested;
      }

      if(this.selectedType === "Update Hotspot Address Request") {
        this.hotspotName = item.hotspotName;
        this.currentAddressLineOne = item.currentAddressLineOne;
        this.currentCity = item.currentCity;
        this.currentCountry = item.currentCountry;
        this.currentState = item.currentState;
        this.currentZip = item.currentZip;
        this.newAddressLineOne = item.newAddressLineOne;
        this.newCity = item.newCity;
        this.newCountry = item.newCountry;
        this.newState = item.newState;
        this.newZip = item.newZip;
        this.newAddressAntennaHeight = item.newAddressAntennaHeight;
        this.reasonOfAddressChange = item.reasonOfAddressChange;
      }

      if(this.selectedType === "No Longer Want Hotspot") {
        this.hotspotName = item.hotspotName;
        this.currentAddressLineOne = item.currentAddressLineOne;
        this.currentCity = item.currentCity;
        this.currentCountry = item.currentCountry;
        this.currentState = item.currentState;
        this.currentZip = item.currentZip;
        this.reasonOfReturn = item.reasonOfReturn;
      }

      this.viewDetailsModel = true;
      
    },
    getuid() {
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios.get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
        headers: { accept: "application/json" },
      })
      .then((resp) => {
        this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
      });
    },
    openUser(userid) {
      this.$router.push({ name: "user", params: { id: userid } });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    getContactUsEmailsOld(){
      axios.get(`${API_BASE_URL}/contactemails`, {
        headers: {  accept: "application/json", "x-access-token": this.token.accessToken, },
      })
      .then((resp) => {
        this.contactEmails = resp.data;
      });
    },
    handelSearch() {
      if(this.options.page > 1) {
        this.options.page = 1;
      } else {
        this.getContactUsEmails();
      }
    },
    handelRequestType() {
      if(this.options.page > 1) {
        this.options.page = 1;
      } else {
        this.getContactUsEmails();
      }
    },
    getContactUsEmails(){
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.contactEmails = [];
      let skip = 0;
      let sortType = 'DESC';
      if(page > 1) {
        skip = itemsPerPage * (page - 1);
      }
      if(this.search != "") {
        skip = 0;
      }
      if(sortDesc.length === 1) {
        if(sortDesc[0]) {
          sortType = 'ASC';
        } else {
          sortType = 'DESC';
        }
      }
      let payload = {
        skip: skip,
        limit: itemsPerPage,
        requestType: this.selectedType,
        search: this.search,
        orderByColumn: (sortBy.length === 1) ? sortBy[0] : 'ID',
        orderByType: sortType,
      };
      this.$root.isLoading = true;
      axios.post(`${API_BASE_URL}/contactemails`, payload, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken
        },
      })
      .then((resp) => {
        this.contactEmails = [];
        this.totalContactEmails = resp.data.totalRequest;
        let requests = resp.data.requests;
        if(requests.length > 0) {
          requests.forEach((element, index) => {
            element.type = resp.data.type;
            this.contactEmails.push(element);
          });
        }
        this.$root.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.$root.isLoading = false;
      });
    }
  },
};
</script>

<style scoped>
  .select-wrapper {
    padding-left: 15px;
  }
</style>