var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-3 purple darken-1"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"text-sm-h5 text-xs-h6 font-weight-bold white--text"},[_vm._v(" Reports ")])])])],1),_c('v-col',{staticClass:"my-2",attrs:{"md":"12 ","cols":"12"}},[_c('v-card',{staticClass:"pa-2",staticStyle:{"height":"100%"},attrs:{"min-height":"300px"}},[_c('v-card-title',[_vm._v(" Offline Hotspots Report "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-btn',{attrs:{"dark":"","color":"green"},on:{"click":function($event){return _vm.getOffline()}}},[_vm._v("Execute"),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-eye ")])],1),_c('v-btn',{attrs:{"dark":"","color":"error"},on:{"click":function($event){_vm.requestLog = []}}},[_vm._v("Clean"),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete-sweep-outline ")])],1),_c('vue-json-to-csv',{attrs:{"csvTitle":"offlineHotspots","json-data":this.requestLog}},[_c('v-btn',{attrs:{"dark":"","color":"green"}},[_vm._v("export"),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-microsoft-excel ")])],1)],1),_c('div',{staticClass:"pa-2"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.requestLog,"item-key":"id","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText},scopedSlots:_vm._u([{key:"item.append",fn:function(){return undefined},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.openUser(item.ID)}}},[_vm._v("Show")])]}}])},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.progressBar),expression:"progressBar"}],attrs:{"slot":"progress","color":"purple darken-4","indeterminate":""},slot:"progress"})],1)],1)],1)],1),_c('v-col',{staticClass:"my-2",attrs:{"md":"12 ","cols":"12"}},[_c('v-card',{staticClass:"pa-2",staticStyle:{"height":"100%"},attrs:{"min-height":"300px"}},[_c('v-card-title',[_vm._v(" Per User Earnings Report "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',{staticClass:"pa-6"},[_c('v-menu',{ref:"menu3",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start date","hint":"Start date","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.date3),callback:function ($$v) {_vm.date3=$$v},expression:"date3"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"min":_vm.mindat,"max":_vm.nowDate},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.date3),callback:function ($$v) {_vm.date3=$$v},expression:"date3"}})],1),_c('v-menu',{ref:"menu4",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End date","hint":"End date","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.date4),callback:function ($$v) {_vm.date4=$$v},expression:"date4"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{attrs:{"max":_vm.nowDate},on:{"input":function($event){_vm.menu4 = false}},model:{value:(_vm.date4),callback:function ($$v) {_vm.date4=$$v},expression:"date4"}})],1)],1),_c('v-btn',{attrs:{"dark":"","color":"green"},on:{"click":function($event){return _vm.getUserearn()}}},[_vm._v("Execute"),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-eye ")])],1),_c('v-btn',{attrs:{"dark":"","color":"error"},on:{"click":function($event){_vm.requestLog3 = []}}},[_vm._v("Clean"),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete-sweep-outline ")])],1),_c('vue-json-to-csv',{attrs:{"json-data":this.requestLog3,"csvTitle":"perUsersEarningsReport"}},[_c('v-btn',{attrs:{"dark":"","color":"green"}},[_vm._v("Export"),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-microsoft-excel ")])],1)],1),_c('div',{staticClass:"pa-2"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers3,"items":_vm.requestLog3,"item-key":"id","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText},scopedSlots:_vm._u([{key:"item.append",fn:function(){return undefined},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.openUser(item.ID)}}},[_vm._v("Show")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }