<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Schedulers
            </div>
            <v-spacer></v-spacer>
            
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Schedulers List
            <v-spacer></v-spacer>
          </v-card-title>

          <div class="pa-2">
            <v-alert type="success" v-if="schedulerSuccessFlag">
              {{runningSchedulerName}} scheduler executed successfully, Please wait for sometime till it process.
            </v-alert>
            <v-alert type="error" v-if="schedulerFailureFlag">
              Opps, Something went wrong.
            </v-alert>
            <v-data-table
              :headers="schedulerHeader"
              :items="schedulers"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`item.last_run`]="{ item }">
                {{changeDateFormat(item.last_run)}}
              </template>
              <template v-slot:[`item.is_active`]="{ item }">
                <span v-if="item.is_active == 1">Active</span>
                <span v-else>InActive</span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn color="success" @click="schedulerLog(item)">Run</v-btn>
              </template>
            </v-data-table>
          </div>

        </v-card>
      </v-col>

    
    </v-row>
  </v-container>
</template>





<script>

import axios from "axios";
import { API_BASE_URL, API_HELIUM_URL } from "../constants";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  setup() {},
  data() {
    return {
      token: JSON.parse(localStorage.getItem("user")),
      search: "",
      schedulers: [],
      schedulerSuccessFlag: false,
      schedulerFailureFlag: false,
      runningSchedulerName: '',
    };
  },
  computed: {
    schedulerHeader() {
      return[
          { text: 'Scheduler Name',value:'scheduler_name'},
          { text: 'Last Run', value: 'last_run' },
          { text: 'Recurrent Pattern', value: 'recurrent_pattern' },
          { text: 'Completed (n) Times', value: 'completed_count' },
          { text: 'Is Active', value: 'is_active' },
          { text: 'Action', value: 'action'},
        ]
    }
  },
  beforeMount() {
    this.fetchSchedulers();
  },
  methods: {
    fetchSchedulers(){
      axios.get(`${API_BASE_URL}/schedulers`, {
        headers: {  accept: "application/json", "x-access-token": this.token.accessToken, },
      })
      .then((resp) => {
        this.schedulers = resp.data;
      });
    },
    changeDateFormat(date){
      return moment(date).format('MM-DD-YYYY');
    },
    runScheduler(scheduler) {
      axios.get(`${API_BASE_URL}${scheduler.url}`, {
        headers: {  accept: "application/json", "x-access-token": this.token.accessToken, },
      })
      .then((resp) => {
        this.$root.isLoading = false;
      })
      .catch((err) => {
        this.$root.isLoading = false;
      });
    },
    schedulerLog(scheduler) {
      this.$root.isLoading = true;
      this.runningSchedulerName = scheduler.scheduler_name;
      let payload = {
        completed_count: scheduler.completed_count + 1,
      };
      axios.put(`${API_BASE_URL}/scheduler/log/${scheduler.id}`, payload, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((response) => {
        this.schedulerSuccessFlag = true;
        this.fetchSchedulers();
        setTimeout(() => { 
          this.runScheduler(scheduler); 
        }, 2000);
      })
      .catch((error) => {
        this.$root.isLoading = false;
        this.schedulerFailureFlag = true;
      });
    }
  }
};
</script>