<template>
  <v-container>
    <v-row>
      <v-col v-if="error" cols="12">
        <v-alert type="error">
          Your Leased Hotspot is in process of being linked with you account.
          The process usually takes a day. If your Hotspot if not visible ,
          please contact our support. <Link> info@jagindustrials.com </Link>
        </v-alert></v-col
      >

      <v-col v-else cols="12">
        <v-card class="pa-3 purple darken-1">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Hotspots
            </div>
            <div
              class="text-sm-h5 text-xs-h6 font-weight-bold white--text"
            ></div>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="notamonth != 1" cols="12">
        <v-alert type="warning">
          You will start earning rewards on {{ hotspotname }} (30 days after
          install)
        </v-alert></v-col
      >
      <v-dialog
      v-model="signneed"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="text-h5">
          New contract awaiting signature.
        </v-card-title>
        <v-card-text>A new hotspot has been assigned to you.  Please sign the contract to see the hotspot details.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
                  <!--  <v-btn
            color="purple darken-1"
            text
            @click="signneed=false"
          >
            Close
          </v-btn>-->
          <v-btn
            color="purple darken-1"
            text
            to="/portalhotspot"
          >
            Go to sign
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <!-- <v-col v-if="signneed == 0" cols="12">

        <v-alert type="warning">
          You have been assigned a new hotspot, sign the contract to see the
          details
          <v-btn color="purple darken-1" to="/sign"> Go to sign</v-btn>
        </v-alert></v-col
      >-->
      <v-col v-if="hotspotstatus == 'offline'" cols="12" v-on:click="showTaC">
        <v-alert type="error">
          A hotspot is offline. Click here for suggestions on how to
          troubleshoot.
        </v-alert></v-col
      >
      <v-col md="4" cols="12" v-show="Hdetails">
        <v-card
          class="my-2 border-left-1"
          v-bind:class="{ error: hotspotstatus == 'offline' }"
        >
          <div>
            <div>
              <v-card-title class="text-h6"> Hotspot Name </v-card-title>
              <v-card-subtitle >
             <span  class="capitalize"> {{ hotspotname.replaceAll('-', ' ') }}</span>
                <span
                  class="mx-3"
                  v-bind:class="{ 'white--text': hotspotstatus == 'offline' }"
                  >({{ hotspotstatus }})</span
                >
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-2">
          <div>
            <div>
              <v-card-title class="text-h6"> Address </v-card-title>

              <v-card-subtitle>
                {{ hotspotadd }}
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-3">
          <div>
            <div>
              <v-card-title class="text-h6"> Installation Date </v-card-title>
              <v-card-subtitle>
                {{ installdatetime }}
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-3">
          <div>
            <div>
              <v-card-title class="text-h6"> Earning Eligibility </v-card-title>

              <v-card-subtitle>
                {{ installation }}
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-4">
          <div>
            <div>
              <v-card-subtitle class="pb-0">
                Total Reward for the selected hotspot
              </v-card-subtitle>
              <v-card-title class="text-h6 gold_4--text">
                {{ totalearn }} HNT
              </v-card-title>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-4"> </v-card>
      </v-col>
      <v-col md="8" cols="12" class="my-2" v-show="Hdetails">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <iframe
            :src="mapurl"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            style="border: 1px solid black"
          ></iframe>
        </v-card>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        Hotspot linked:
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="hotspotAll"
        class="elevation-1"
        :loading="false"
        :search="search"
        item-key="ID"
        :item-class="itemRowBackground"
        :custom-filter="filterOnlyCapsText"
      >
        <v-progress-linear
          v-show="progressBar"
          slot="progress"
          color="purple darken-4"
          indeterminate
        ></v-progress-linear>
                <template v-slot:item.name="{ item }" >
         <td class="capitalize">{{ item.name.replaceAll('-', ' ')}}</td>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn color="success" @click="getHotspot(item)">show </v-btn>
        </template>
        <template v-slot:item.role="{ item }">
          <v-chip
            class="ma-2"
            color="purple darken-1"
            dark
            v-if="item.role === '0'"
          >
            Primary Owner
          </v-chip>
          <v-chip
            class="ma-2"
            dark
            color="purple darken-4"
            v-else-if="item.role === '1'"
          >
            Referral Owner
          </v-chip>
          <v-chip
            class="ma-2"
            dark
            color="green accent-2"
            v-else-if="item.role === '2'"
          >
            Watchers
          </v-chip>
          <v-chip class="ma-2" dark color="danger" v-else> Error </v-chip>
        </template>
        <template v-slot:item.signed="{ item }">
          <v-icon v-if="item.signed == 1 && item.role != '2'"
            >mdi-account-check</v-icon
          >
          <v-icon v-if="item.signed == 0 && item.role != '2'"
            >mdi-alert-circle</v-icon
          >
        </template>
        <template v-slot:item.timestamp_added="{ item }">
          <span v-if="item.installDate == null">{{
            item.timestamp_added.slice(0, 10)
          }}</span>
          <span v-else>{{ item.installDate.slice(0, 10) }}</span>
        </template>
        <template v-slot:item.geocode.short_state="{ item }">
          <span v-if="item.removeDate!=null && item.role == 0">{{"N/A"}}</span>
          <span v-else
            >{{ item.geocode.short_state }}{{ " "
            }}{{ item.geocode.short_street }}{{ " "
            }}{{ item.geocode.short_country }}</span
          >
        </template>
                <template v-slot:item.email="{ item }">
          <span v-if="item.removeDate != null && item.role == 0">{{
            "N/A"
          }}</span>
          <span v-else>{{ item.email }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { API_BASE_URL, API_HELIUM_URL } from '../../constants';

export default {
  data() {
    return {
      dialog: false,
      hotspotDetails: [],
      hotspotTotal: [],
      url: "",
      progressBar: true,
      mapurl: "",
      error: false,
      start: "",
      today1: "",
      totalearn: 0,
      Hdetails: false,
      notamonth: 0,
      hotspotstatus: "",
      installdatetime: "",
      hotspotname: "",
      hotspotadd: "",
      hotspotAll: [],
      message: "",
      uid: localStorage.getItem('user-portal-uid'),
      hname: "",
      commission: 20,
      haddress: "",
      installation: "",
      first30days: 0,
      today: 0,
      signneed: false,
      sums: 0,
      yesterday: 0,
      search: "",
      week: 0,
      arr: [],
      userEmail: ''
    };
  },
  watch: {
    hotspotAll() {
      this.progressBar = false;
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    headers() {
      return [
        { text: "Hotspot Name", value: "name" },
        { text: "Status", value: "status.online" },
        //{ text: "address", value: "Haddress" },
        { text: "Relationship", value: "role" },
        { text: "Email", value: "email" },
        //{ text: "country", value: "geocode.short_country"},
        // { text: "street", value: "geocode.short_street"},
        { text: "Location", value: "geocode.short_state" },
        { text: "Date of Installation", value: "timestamp_added" },
        //
        // { text: "contract status", value: "signed" },
        // { text: "Start earning Date", value: "elegibility" },
        { text: "Today", value: "today" },
        { text: "Past 7 Days", value: "week" },
        { text: "Past 30 Days", value: "month" },
        { text: "All Time Earnings", value: "total" },
        { text: "Action", value: "action", sortable: false, align: "center" },
      ];
    },
  },
  beforeMount() {
    this.getuid();
  },

  methods: {
    getHotspot(hname) {
      this.Hdetails = true;
      this.haddress = hname.address;
      //this.getbalance(haddress1, this.installation, enddate, this.commission);
      this.hotspotadd = hname.address;
      this.hotspotstatus = hname.status.online;
      this.notamonth = hname.notmonth;
      if (hname.total != undefined) {
        this.totalearn = hname.total;
      } else {
        this.totalearn = 0;
      }
      this.hotspotname = hname.name;
      if (hname.installDate != null) {
        this.installdatetime = hname.installDate.substring(0, 10);
      } else {
        this.installdatetime = hname.timestamp_added.substring(0, 10);
      }
      this.installation = hname.elegibility;
      this.mapurl = [
        "https://www.openstreetmap.org/export/embed.html?bbox=" +
          hname.lng +
          "%2C" +
          hname.lat +
          "%2C" +
          hname.lng +
          "%2C" +
          hname.lat +
          "&layer=mapnik&marker=" +
          hname.lat +
          "%2C" +
          hname.lng,
      ].join("");
    },

    showTaC() {
      var pdf = `${API_BASE_URL}/gethelp`;
      window.open(pdf);
    },
    getuid() {
      this.getglobal();
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          try {
            this.$cookies.set("hotspotStatus", false);
            this.parsingName(resp.data);


          } catch (err) {
            this.error = true;
            this.$cookies.set("hotspotStatus", true);
            console.log("empty profile");
          }
        });
    },
    parsingName(arr3) {
      var arr1 = [];
      var arr2 = [];
      var filteredHotspot = [];
      let promises = [];
      arr3.forEach((element) => {
        if(element.Haddress != 'empty'){

           var today = new Date();
           var end = element.removeDate? new Date(element.removeDate.slice(0,10)):today;

           //compare only date
           today.setHours(0,0,0,0)

           
           //check hotspot end date display accordingly
          //  if(end >= today){
          //     promises.push(
          //       axios.get(`${API_HELIUM_URL}/v1/hotspots/${element.Haddress}`, {
          //         headers: { accept: "application/json" },
          //       })
          //     );

          //     //filter hotspot remove date wise
          //     filteredHotspot.push(element);
          //  }

          promises.push(
            axios.get(`${API_HELIUM_URL}/v1/hotspots/${element.Haddress}`, {
              headers: { accept: "application/json" },
            })
          );

          filteredHotspot.push(element);

        }
      });
      Promise.all(promises).then((results) => {
        results.forEach((resp) => {
          arr2.push(resp.data.data);
          arr1.push(resp.data.data.name);
        });
        this.remainpayment(arr1, arr2, filteredHotspot);
      });
    },
    itemRowBackground(item) {
      if(item.removeDate !== null) {
        return 'style-3';
      }
      else if(item.status.online == "offline") {
        return 'style-1'
      }
      else {
        return 'style-2';
      }
      //return item.status.online == "offline" ? "style-1" : "style-2";
    },
    remainpayment(arr1, arr2, arr3) {
      this.ownedhotspot = arr1; //name hotspot
      this.detailhotspot = arr2; //helium data
      for(let i = 0; i < arr3.length; i++){
        if(arr3[i].Haddress != 'empty'){
          this.hotspotAll = [...this.hotspotAll, arr3[i]];
        }
      }
     
      var i;
      var conc = {};
      let details = this.detailhotspot;
      this.remainbalance = 0;
      for (i = 0; i < details.length; i++) {
        for (var attrname in this.hotspotAll[i]) {
          conc[attrname] = this.hotspotAll[i][attrname];
        }
        conc["elegibility"] = null;
        conc["today"] = (0).toFixed(2);
        conc["week"] = (0).toFixed(2);
        conc["month"] = (0).toFixed(2);
        conc["total"] = (0).toFixed(2);
        conc["notmonth"] = 0;
        conc["email"] = null;
        for (var attrname1 in details[i]) {
          conc[attrname1] = details[i][attrname1];
        }

        this.hotspotAll[i] = conc;

        conc = {};
      }

      this.hotspotAll.forEach((element) => {
        //jag value + helium value
        var endinst = null;
        var [year, month, day] = element.timestamp_added
          .substring(0, 10)
          .split("-");
        var installdatetime = "";
        var installation = new Date(year, month - 1, day);
        if (element.installDate != null) {
          installdatetime = element.installDate.slice(0, 10);
        } else {
          installdatetime = installation.toISOString().slice(0, 10);
        }
        if (element.removeDate != null) {
          endinst = "&max_time=" + element.removeDate.slice(0, 10);
        }
        this.installdatetime = this.checkfirstdays(
          element.role,
          installdatetime,
          element.firstdays
        );
        if (element.role == 1) {
          //if refer owner
          if (element.firstdays == 0) {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              element.commission,
              element.firstdays,
              "refer"
            );
          } else {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              element.commission,
              element.firstdays,
              "refer"
            );
          }
        }
        if (element.role == 0) {
          //if primary owner
          if (this.first30days == 1) {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              this.commission,
              this.first30days,
              "primary"
            );
          } else {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              this.commission,
              this.first30days,
              "primary"
            );
          }
        }
      });

      console.log('this.hotspotAll', this.hotspotAll);
      console.log('arr3', arr3)

      for(let i = 0; i < arr3.length; i++){
        
        if(arr3[i].Haddress == 'empty'){
          let data = {
            name: arr3[i].Haddress,
            status: {
              online: ''
            },
            role: arr3[i].role,
            email: this.userEmail,
            geocode: {
              short_state: ''
            },
            timestamp_added: '',
            today: '',
            week: '',
            month: '',
            total: ''
          }

          this.hotspotAll = [...this.hotspotAll, data];
        }
      }

      console.log('new hotspotall', this.hotspotAll);
    },
    checkfirstdays(role, startdate, firstdays) {
      var [year, month, day] = startdate.substring(0, 10).split("-");
      var installation = new Date();
      if (role == 1) {
        if (firstdays == 0) {
          //30 days NO
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        }
      } else {
        //primary owner
        if (this.first30days == 0) {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        }
      }
      this.installation = installation.toISOString().substring(0, 10);
      return installation.toISOString().substring(0, 10);
    },
    getglobal() {
      axios
        .get(`${API_BASE_URL}/user/info/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            this.userEmail = resp.data[0].email;
            if(resp.data[0].investor == 0){
               if(resp.data[0].signed==1 || resp.data[0].signed==null){this.signneed=false}
              else{
                //this.signneed=true  //we are moving this functionality to shopify 

                }
                (this.commission = resp.data[0].commission),
                (this.first30days = resp.data[0].FirstDays);
            }
          } catch (err) {
            this.error = true;
          }
        });
    },

    getDayscalc(i, name, haddress, installation, end, commission,final_comm) {
      var [year, month, day] = installation.substring(0, 10).split("-");
      installation = new Date(year, month - 1, day);
      var today = new Date();
      var ends = new Date();
      ends.setDate(today.getDate() - end);
      if (ends >= installation) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${ends
              .toISOString()
              .slice(0, 10)}&max_time=${today.toISOString().slice(0, 10)}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            this.hotspotAll[i][name] = (((resp.data.data.total-(resp.data.data.total / 100) * final_comm))/ 100 * commission).toFixed(2);
          })
          .catch((err) => {
            return 0;
          });
      }
    },
            getbalance(address, dateinst, endinst, commission,firstday, type) {
      var final_comm = 0;
      if (type == "refer") {
        axios
          .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            if (resp.data.length != 0) {
              final_comm = resp.data[0].commission;
            } else {
              final_comm = 20;
            }
            this.getbalanceStep2(
              address,
              dateinst,
              endinst,
              commission,
              final_comm
            );
          });
      } else {
        this.getbalanceStep2(
          address,
          dateinst,
          endinst,
          commission,
          final_comm
        );
      }
    },
    getbalanceStep2(address, dateinst, endinst, commission, final_comm) {
      var totalearn = 0;
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst.slice(
            0,
            10
          )}${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          totalearn = ((resp.data.data.total-(resp.data.data.total / 100) * final_comm))/ 100 * commission;
          for (var i = 0; i < this.hotspotAll.length; i++) {
            if (this.hotspotAll[i]["address"] == address) {
              this.hotspotAll[i]["total"] = totalearn.toFixed(2);
              this.getOwner(address, i);
              this.getDayscalc(i, "today", address, dateinst, 1, commission,final_comm);
              this.getDayscalc(i, "month", address, dateinst, 30, commission,final_comm);
              this.getDayscalc(i, "week", address, dateinst, 7, commission,final_comm);
              this.hotspotAll[i]["elegibility"] = dateinst;
              this.hotspotAll[i]["notmonth"] = 1;
              this.getHotspot(this.hotspotAll[0]);
            }
          }
        })
        .catch((resp) => {
          for (var i = 0; i < this.hotspotAll.length; i++) {
            if (this.hotspotAll[i]["address"] == address) {
              this.hotspotAll[i]["total"] = (0).toFixed(2);
              this.getOwner(address, i);
              this.hotspotAll[i]["elegibility"] = dateinst;
              this.hotspotAll[i]["today"] = (0).toFixed(2);
              this.hotspotAll[i]["week"] = (0).toFixed(2);
              this.hotspotAll[i]["month"] = (0).toFixed(2);
              this.hotspotAll[i]["notmonth"] = 0;
              this.getHotspot(this.hotspotAll[0]);
            }
          }
        });
    },
    getOwner(address, i) {
      axios
        .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
            this.hotspotAll[i]["email"] = resp.data[0]?resp.data[0].email:"N/A";
        });
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
  },
};
</script>
<style>
.capitalize {
    text-transform: capitalize;
}
.style-1 {
  background-color: #ff5252;
}
.style-2 {
  background-color: rgb(255, 255, 255);
}
.style-3 {
  background-color: rgb(192,192,192);
}
</style>