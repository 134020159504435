<template>
    <v-app-bar
            app
            class="white px-md-5"
            elevation="0"
            :height="$vuetify.breakpoint.mdAndUp ? '100px' : '70px'"
    >
        <v-container>
            <div class="d-none d-md-block">
                <v-row align="center">
                    <div class="d-flex align-center">
                        <a href="https://www.jag.network/">
                            <v-img
                                    alt="Logo"
                                    class="shrink mr-2"
                                    contain
                                    src="@/assets/logo.png"
                                    transition="scale-transition"
                                    width="95"
                            />
                        </a>                        
                    </div>
                                            <div class="mr-2 subtitle-2">HNT Conversion Price:
                            <span class="gold_4--text darken-4 ml-2" @click="HNTopen()">
                               $ {{ (oracleprice.data.price/100000000).toFixed(2) }}
                            </span>
                        </div>
                    <v-spacer></v-spacer>
                    <div >
                        <router-link v-if="investor=='1'" :to="'/portalinvestor'" class="menu-item">Investor</router-link>
                        <!--                    <span class="mr-2">Oracle Price: </span><span class="yellow&#45;&#45;text darken-4">{{ oracleprice.data.price/100000000 }} $ </span>-->
                        <router-link  :to="'/portaldashboard'" class="menu-item">Dashboard</router-link>
                    <!--<<router-link :to="{name:'account'}" class="menu-item">Account</router-link>-->
                        <router-link :to="'/portalhotspot'" class="menu-item">Hotspot</router-link>
                        <!--<router-link :to="'/rewards'" class="menu-item">Rewards</router-link>-->
                        <router-link  :to="'/portalprofile'" class="menu-item">Your Profile</router-link>
                        <router-link  :to="'/portalreward'" class="menu-item">Rewards</router-link>
                        <router-link  :to="'/portalreports'" class="menu-item">Reports</router-link>
                                    <router-link :to="'/portalcontactus'" class="menu-item">Support</router-link>

                    </div><div></div>
                                        


                </v-row>
            </div>

            <v-row align="center" class="d-md-none my-0 justify-space-between">
                <v-menu 
                        left
                        bottom
                        offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <template>
                            <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                <v-icon>mdi-menu</v-icon>
                            </v-btn>
                        </template>

                    </template>

                    <v-list>
                        <v-list-item :to="'/portaldashboard'" dense 
                        >
                            <v-list-item-title class="subtitle-2">Dashboard</v-list-item-title>
                        </v-list-item>
                     <!--   <v-list-item :to="{name:'account'}" dense
                        >
                            <v-list-item-title class="subtitle-2">Account</v-list-item-title>
                        </v-list-item>-->
                        <v-list-item :to="'/portalhotspot'" dense
                        >
                            <v-list-item-title class="subtitle-2">Hotspot</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="'/portalprofile'" dense 
                        >
                            <!--<v-list-item-title class="subtitle-2">Rewards</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="'/contact'" dense
                        >-->
                            <v-list-item-title class="subtitle-2">Your Profile</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="'/portalreward'" dense 
                        >
                            <v-list-item-title class="subtitle-2">Rewards</v-list-item-title>
                        </v-list-item>

                        <!--<v-list-item :to="'/help'" dense
                        >
                            <v-list-item-title class="subtitle-2">Help</v-list-item-title>
                        </v-list-item>-->
                          <v-list-item v-if="investor=='1'" :to="{name:'portalinvestor'}" dense
                        >
                            <v-list-item-title class="subtitle-2">Investor</v-list-item-title>
                        </v-list-item>
                                   <v-list-item :to="'/portalcontactus'" dense>
              <v-list-item-title class="subtitle-2">Support</v-list-item-title>
            </v-list-item>
                    </v-list>
                </v-menu>
                <div class="d-flex align-center">
                    <router-link to="/">
                        <v-img
                                alt="Logo"
                                class="shrink mr-2"
                                contain
                                src="@/assets/logo.png"
                                transition="scale-transition"
                                width="65"
                        />
                    </router-link>
                </div>

                <v-btn href="/login"
                       color="purple darken-1"
                       dark
                       outlined
                       rounded
                       small>
                    <span class="mr-2">Login</span>
                </v-btn>
            </v-row>
        </v-container>
    </v-app-bar>
</template>

<script>
    import axios from 'axios';
  import { API_BASE_URL, API_HELIUM_URL } from '../../constants'; 
    export default {
        name: "app-header",
        data: () => {
            return {
                oracleprice: {
                    data: {
                        price: 0
                    }
                },
                investor:0
            }
        },
        computed: {
        },

        mounted() {
            this.getOracleValue();
            this.getStatus()
        },
        methods: {
            getOracleValue() {
                axios.get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
                    headers: {
                        'accept': 'application/json'
                    },
                }).then((resp) => {
                    this.oracleprice = resp.data;
                })
            },
            HNTopen(){
                window.open("https://coinmarketcap.com/currencies/helium/", "_blank")

            },
                        getStatus(){
                //console.log(this.$cookies.get("uid"))
                axios
        .get(`${API_BASE_URL}/user/info/${localStorage.getItem('user-portal-uid')}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
              console.log(resp),
            //if(resp.data[0].signed==1){this.signneed=false}
            //else{this.signneed=true}
              (this.investor = resp.data[0].investor)
              
          } catch (err) {
            this.error = true;
            console.log("empty profile");
          }
        });
            },


        }
    }
</script>

<style scoped lang="scss">
    .menu-item {
        text-decoration: none !important;
        padding: 5px 10px;
        color: var(--v-secondary-base);
        font-size: 14px;

        &:hover {
            color: var(--v-primary-base);
        }

        &.router-link-exact-active {
            color: var(--v-gold_4-base);
        }

        &:not(:last-child) {
            border-right: 1px solid var(--v-primary-base);
        }
    }
</style>