<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Admin User Managment
            </div>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              dark
              small
              v-bind="attrs"
              v-on="on"
              :to="'/register'"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-dialog v-model="restore" max-width="500">
        <v-card class="pa-3" style="height: 100%" min-height="200px">
          <v-card-title class="text-h5">
            Set New Password for {{ user.username }}
          </v-card-title>
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Enter new password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="restore = false">
              Close
            </v-btn>

            <v-btn color="green darken-1" text @click="restoreUser(user)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="roleModal" max-width="500">
        <v-card class="pa-3" style="height: 100%" min-height="200px">
          <v-card-title class="text-h5"> Update Role </v-card-title>

          <v-select
            :items="roles"
            label="Select Role"
            v-model="selectedRole"
            item-value="id"
            item-text="name"
          ></v-select>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="roleModal = false">
              Close
            </v-btn>

            <v-btn color="green darken-1" text @click="updateUserRole(user)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-alert
            dismissible
            type="success"
            v-if="resetPasswordMailFlag === true"
          >
            {{ resetPasswordMailResponseMessage }}
          </v-alert>
          <v-alert
            dismissible
            type="error"
            v-if="resetPasswordMailFlag === false"
          >
            {{ resetPasswordMailResponseMessage }}
          </v-alert>
          <v-card-title>
            User
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @keyup="handelSearch();"
            ></v-text-field>
          </v-card-title>
          <div class="pa-2">
            <v-data-table
              :headers="headers"
              :items="requestLog"
              :options.sync="options"
              :server-items-length="totalAdminUsers"
              :footer-props="{'itemsPerPageOptions':[5, 10, 15]}"
              :loading="isSearching"
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:[`item.role`]="{ item }">
                <span v-if="item.role">{{ item.role }}</span>
                <span v-else>N/A</span>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-btn
                  v-if="item.is_admin_active == 0"
                  color="success"
                  @click="makeAdminActiveOrInactive(item.id, item.is_admin_active)"
                  >Set Active</v-btn
                >
                <v-btn
                  v-if="item.is_admin_active == 1 && isSuperAdmin == 1"
                  color="red"
                  @click="makeAdminActiveOrInactive(item.id, item.is_admin_active)"
                  >Set InActive</v-btn
                >
                <span v-if="item.is_admin_active == 1 && isSuperAdmin == 0"
                  >Active</span
                >
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <!-- <v-btn color="success" @click="restorePass(item)">
                  Restore Password
                </v-btn> -->
                <v-btn color="success" @click="sendResetPasswordMail(item)">
                  Restore Password
                </v-btn>
                <v-btn color="warning" @click="updateRoleModal(item)">
                  Update Role
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { API_BASE_URL, API_HELIUM_URL } from "../constants";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import moment from "moment";

export default {
  setup() {},
  data() {
    return {
      //options: [{ id: "CA", name: "Canada" }],
      show1: false,
      password: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
      roles: [],
      selectedRole: "",
      message: "",
      amount: 0,
      dialog: false,
      hotspotDetails: [],
      user: {},
      hotspotTotal: [],
      url: "",
      mapurl: "",
      token: JSON.parse(localStorage.getItem("user")),
      start: "",
      today: "",
      restore: false,
      roleModal: false,
      search: "",
      uid: "",
      oracleprice: null,
      installation: "",
      requestLog: [],
      maxearn: 0,
      attrs: "",
      on: "",
      isSuperAdmin: 1,
      resetPasswordMailFlag: null,
      resetPasswordMailResponseMessage: "",
      options: {},
      totalAdminUsers: 0,
      isSearching: false
    };
  },
  computed: {
    headers() {
      return [
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Created At", value: "createdAt" },
        { text: "Last Update", value: "updatedAt" },
        { text: "Status", value: "status", sortable: false, align: "center" },
        { text: "Actions", value: "action", sortable: false, align: "center" },
      ];
    },
  },
  beforeMount() {
    //this.getuid();
    let userObject = JSON.parse(localStorage.getItem("user"));
    console.log("userObject", userObject);
    if (userObject.role_id == 1) {
      this.isSuperAdmin = 1;
    } else {
      this.isSuperAdmin = 0;
    }
    var start = new Date();
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var m2 = today.getMonth() - 2;
    var yyyy = today.getFullYear();
    var yyyy2 = today.getFullYear() - 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (m2 < 10) {
      m2 = "0" + m2;
    }
    this.today = yyyy + "-" + mm + "-" + dd;
    this.start = yyyy2 + "-" + m2 + "-" + dd;
    //this.getRequest();
    this.getRoles();
    this.getOracleValue();
  },
  watch: {
    options: {
      handler () {
        this.getRequest();
      },
      deep: true,
    },
  },

  methods: {
    getRoles() {
      this.$root.isLoading = true;
      this.roles = [];
      axios
        .get(`${API_BASE_URL}/admin/get-roles`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          this.$root.isLoading = false;
          // this.roles = resp.data;

          resp.data.forEach((element) => {
            this.roles.push({ id: element.id, name: element.name });
          });
          console.log("afq", this.roles);
        })
        .catch((err) => {
          console.log(err);
          this.$root.isLoading = false;
        });
    },
    sendResetPasswordMail(user) {
      this.$root.isLoading = true;
      // setTimeout(() => {
      //   this.$root.isLoading = false;
      //   this.resetPasswordMailFlag = true;
      //   this.resetPasswordMailResponseMessage = "Reset password mail sent successfully."
      // },5000);
      let payload = {
        userId: user.id,
      };
      axios
        .post(`${API_BASE_URL}/send/reset/password/email`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((response) => {
          this.resetPasswordMailFlag = true;
          this.resetPasswordMailResponseMessage =
            "Reset password mail sent successfully.";
          this.$root.isLoading = false;
        })
        .catch((error) => {
          this.resetPasswordMailFlag = false;
          this.resetPasswordMailResponseMessage =
            "Opps, Something went wrong while sending mail.";
          this.$root.isLoading = false;
        });
    },
    getOracleValue() {
      axios
        .get(`https://api.helium.io/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data;
        });
    },
    restorePass(item) {
      (this.restore = true), (this.user = item);
    },
    updateRoleModal(item) {
      this.roleModal = true;
      this.user = item;
    },
    restoreUser(item) {
      this.restore = false;
      let payload = {
        password: this.password,
      };
      axios
        .put(`${API_BASE_URL}/restore/${item.id}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((res) => {
          this.password = "";
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateUserRole(item) {
      this.roleModal = false;
      let payload = {
        role_id: this.selectedRole,
        admin : this.token.email
      };
      axios
        .put(`${API_BASE_URL}/update-admin-role/${item.id}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((res) => {
          this.selectedRole = "";
          this.getRequest();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendRequest(amount, message) {
      console.log(this.message);
      axios.post(`${API_BASE_URL}/user/request/${this.uid}`, {
        payment: this.message,
        amount: this.amount,
      });
      this.dialog = false;
      this.getRequest();
    },
    getuid() {
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
        });
    },
    hotspotDet(address, start, today) {
      (this.dialog = false),
        axios
          .get(`https://api.helium.io/v1/hotspots/${address}`, {
            headers: { accept: "application/json" },
          })
          .then((resp) => {
            this.hotspotDetails = resp.data;
            var [year, month, day] = this.hotspotDetails.data.timestamp_added
              .substring(0, 10)
              .split("-");
            this.installation = new Date(year, month, day);
            axios
              .get(
                `https://api.helium.io/v1/hotspots/${address}/rewards/sum?min_time=${this.installation
                  .toISOString()
                  .slice(0, 10)}`,
                {
                  headers: { accept: "application/json" },
                }
              )
              .then((resp) => {
                this.hotspotTotal = resp.data;
                this.mapurl = [
                  "https://www.openstreetmap.org/export/embed.html?bbox=" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "&layer=mapnik&marker=" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng,
                ].join("");
                this.maxearn = parseInt(
                  ((this.hotspotTotal.data.total / 100) * 20).toFixed(2)
                );
              });
          });
    },
    openUser(userid) {
      this.$router.push({ name: "user", params: { id: userid } });
    },
    getRequestOld() {
      this.$root.isLoading = true;
      axios.get(`${API_BASE_URL}/adminusers`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        this.requestLog = resp.data;
        this.requestLog.forEach((obj) => {
          obj["createdAt"] = obj["createdAt"]
            ? moment(obj["createdAt"]).format("MM-DD-YYYY h:mm:ss a")
            : "N/A";
          obj["updatedAt"] = obj["updatedAt"]
            ? moment(obj["updatedAt"]).format("MM-DD-YYYY h:mm:ss a")
            : "N/A";
        });
        this.$root.isLoading = false;
      });
    },
    getRequest() {
      if(this.isSearching === false) {
        this.$root.isLoading = true;
      }
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.requestLog = [];
      let skip = 0;
      let sortType = 'DESC';
      if(page > 1) {
        skip = itemsPerPage * (page - 1);
      }
      if(this.search != "") {
        skip = 0;
      }
      if(sortDesc.length === 1) {
        if(sortDesc[0]) {
          sortType = 'ASC';
        } else {
          sortType = 'DESC';
        }
      }
      let payload = {
        skip: skip,
        limit: itemsPerPage,
        search: this.search,
        orderByColumn: (sortBy.length === 1) ? sortBy[0] : 'id',
        orderByType: sortType,
      };
      axios.post(`${API_BASE_URL}/adminusers`, payload, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        this.totalAdminUsers = resp.data.totalAdminUsers;
        this.requestLog = resp.data.adminUsers;
        this.requestLog.forEach((obj) => {
          obj["createdAt"] = obj["createdAt"]
            ? moment(obj["createdAt"]).format("MM-DD-YYYY h:mm:ss a")
            : "N/A";
          obj["updatedAt"] = obj["updatedAt"]
            ? moment(obj["updatedAt"]).format("MM-DD-YYYY h:mm:ss a")
            : "N/A";
        });
        this.isSearching = false;
        this.$root.isLoading = false;
      });
    },
    handelSearch() {
      this.isSearching = true;
      if(this.options.page > 1) {
        this.options.page = 1;
      } else {
        this.getRequest();
      }
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },

    saveHotspot(address) {
      let payload = { haddress: address };
      let res = axios.put(`${API_BASE_URL}/hotspot/${this.uid}`, payload);
      let data = res.data;
    },
    makeAdminActiveOrInactive(userID, status) {
      let changeStatus = status == 0 ? 1 : 0;
      let statusName = changeStatus == 0 ? "Inactive" : "active";
      if (confirm(`Do you really want to set this user ${statusName}?`)) {
        axios
          .get(
            `${API_BASE_URL}/makeAdminActiveOrInactive/${changeStatus}/${userID}`,
            {
              headers: {
                accept: "application/json",
                "x-access-token": this.token.accessToken,
              },
            }
          )
          .then((resp) => {
            this.getRequest();
          });
      }
    },
  },
};
</script>