<template>
  <v-container class="my-7">
    <v-card class="ma-auto">
      <v-row class="mx-0">
      
   

      

      


     

     
        <v-col sm="8" cols="12" class="px-7 py-3">
          <v-row>
          
          
          <v-form ref="form" autocomplete="off">
            <div class="headline secondary--text font-weight-bold my-2">
              Email Settings
            </div>
            <v-row>
              <v-col sm="6" cols="12">
                <v-text-field
                  v-model="emailConfiguration.api_key"
                  label="Api Key *"
                  hide-details
                  :rules="rules1.requiredRules"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6" cols="3">
                <v-text-field
                  v-model="emailConfiguration.from_email"
                  label="From Email Address *"
                  autocomplete="off"
                  type="text"
                   :rules="rules1.emailRules"
                >
                </v-text-field>
              </v-col>

              <v-col sm="6" cols="12">
                <v-text-field
                  v-model="emailConfiguration.from_name"
                  label="From Name *"
                  type="text"
                  autocomplete="off"
                  :rules="rules1.requiredRules"
                >
                </v-text-field>
              </v-col>

            
            </v-row>
            
            <!--<div class="headline secondary--text font-weight-bold my-2">
              Installation details:
            </div>
            <v-text-field
              v-model="contact.Wifiname"
              label="Wifiname"
              type="text"
              autocomplete="off"
            >
            </v-text-field>
            <v-text-field
              v-model="contact.Wifipassword"
              label="Wifipassword"
              type="text"
              autocomplete="off"
            >
            </v-text-field>
            <v-text-field
              v-model="contact.AntennaType"
              label="AntennaType"
              type="text"
              autocomplete="off"
            >
            </v-text-field>
            <v-text-field
              v-model="contact.AntennaLocation"
              label="AntennaLocation"
              type="text"
              autocomplete="off"
            >
            </v-text-field>
            <v-text-field
              v-model="contact.Hotspotconnection"
              label="Hotspotconnection"
              type="text"
              autocomplete="off"
            >
            </v-text-field>-->

            <v-row v-if="sucessAlert" style="margin-top: 24px">
              <v-alert type="success" transition="fade-transition">
                Email Settings updated Successfully.
              </v-alert>
            </v-row>

            <v-btn
              class="blue darken-4 white--text my-3 float-right"
              @click="save"
              >Save</v-btn
            >
          </v-form>
           </v-row>
        </v-col>
      
      </v-row>
     
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import { API_BASE_URL, API_HELIUM_URL, API_HELIUM_NODE_JAG_URL } from "../constants";
import moment from "moment";
export default {
  name: "App",
  data() {
    return {
      items: ["TLS", "SSL"],
      sucessAlert: false,
      emailConfiguration:{},
      token: JSON.parse(localStorage.getItem("user")),
     
    

      nameRules: [(v) => !!v || "Required"],
      rules1: {
        requiredRules: [(v) => !!v || "This field is required"],
        emailRules: [
                    v => !!v || "This field is required",
                    v => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) || 'Email must be valid',
                ],
      },
    };
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  beforeMount() {
    this.getAddress();
    //console.log(this.accountAdded);
  },
  methods: {


  


    getAddress() {
      axios
        .get(`${API_BASE_URL}/emailsetting-data`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.emailConfiguration = resp.data[0];
          console.log(this.emailConfiguration);
        });
    },
    getuid() {
      this.alreadypayed = 0;
      axios
        .get(`${API_BASE_URL}/user/${this.$route.params.id}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          try {
            if(resp.data.length > 0) {
              this.info = resp.data[0];
              this.signed = this.info.signed;
              this.signedStatus = this.trfal[this.info.signed];
              if (resp.data[0].FirstDays == 0) {
                this.FirstDays = "YES";
              } else {
                this.FirstDays = "NO";
              }
              this.uid = resp.data[0].UserID;
              console.log("invest", this.info.investor);
              if (this.info.investor == "0" || this.info.investor == undefined) {
                this.investorstatus = false;
              } else {
                this.investorstatus = true;
              }
              console.log("after", this.investor);
              this.populateAdminField(resp.data[0]);
              //this.getHotspotName();
              //function to fetch user hotspots
              this.fetchUserHotspot();
              //function to fetch user earnings
              this.fetchUserEarnedAndMinedHNT();
              this.getpayment();
              this.getRequest();
              this.getwithdrawal();
              this.getInvest();
              this.getchat();
            }
            
          } catch (err) {
            console.log("empty profile");
          }
        });
    },



 
    save() {
      if (this.$refs.form.validate()) {
        console.log(this.emailConfiguration);
        let payload = this.emailConfiguration;
        axios.put(`${API_BASE_URL}/emailsetting-data`, payload).then(res=>{
          if(res.status == 200){
            this.sucessAlert = true;
          }
        })
      }
    },
   
  },
};
</script>
<style>
.contact-icon {
  background: white;
  border-radius: 50%;
  padding: 10px;
  margin: 20px;
}
</style>