<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 purple darken-1">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Reports
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Offline Hotspots Report
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search" 
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-btn dark color="green" @click="getOffline()"
            >Execute<v-icon dark> mdi-eye </v-icon></v-btn
          >
          <v-btn dark color="error" @click="requestLog = []"
            >Clean<v-icon dark> mdi-delete-sweep-outline </v-icon></v-btn
          >
          <vue-json-to-csv
            csvTitle="offlineHotspots"
            :json-data="this.requestLog"
            ><v-btn dark color="green"
              >export<v-icon dark> mdi-microsoft-excel </v-icon></v-btn
            >
          </vue-json-to-csv>
          <div class="pa-2">
            <v-data-table
              :headers="headers"
              :items="requestLog"
              item-key="id"
              class="elevation-1"
              :search="search"
              :custom-filter="filterOnlyCapsText"
            >
              <v-progress-linear
                v-show="progressBar"
                slot="progress"
                color="purple darken-4"
                indeterminate
              ></v-progress-linear>
              <template v-slot:item.append> </template>
              <template v-slot:item.action="{ item }">
                <v-btn color="success" @click="openUser(item.ID)">Show</v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Per User Earnings Report
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-row class="pa-6">
         <!--   <v-autocomplete
                    :items="allUser"
                    item-text="email"
                    label="Email user"
                    v-model="SelectedUser"
                  ></v-autocomplete>-->
                              <!-- <v-autocomplete
                    :items="allUser"
                    :item-text="getItemText"
                    label="Username - Email"
                    v-model="SelectedUser"
                  ></v-autocomplete> -->
            <v-menu
              ref="menu3"
              v-model="menu3"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date3"
                  label="Start date"
                  hint="Start date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date3"
                :min="mindat"
                :max="nowDate"
                @input="menu3 = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              ref="menu4"
              v-model="menu4"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date4"
                  label="End date"
                  hint="End date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date4"
                :max="nowDate"
                @input="menu4 = false"
              ></v-date-picker> </v-menu
          ></v-row>
          <v-btn dark color="green" @click="getUserearn()"
            >Execute<v-icon dark> mdi-eye </v-icon></v-btn
          >
          <v-btn dark color="error" @click="requestLog3 = []"
            >Clean<v-icon dark> mdi-delete-sweep-outline </v-icon></v-btn
          >
          <vue-json-to-csv :json-data="this.requestLog3" csvTitle="perUsersEarningsReport"
            ><v-btn dark color="green"
              >Export<v-icon dark> mdi-microsoft-excel </v-icon></v-btn
            >
          </vue-json-to-csv>
          <div class="pa-2">
            <v-data-table
              :headers="headers3"
              :items="requestLog3"
              item-key="id"
              class="elevation-1"
              :search="search"
              :custom-filter="filterOnlyCapsText"
            >
              <template v-slot:item.append> </template>
              <template v-slot:item.action="{ item }">
                <v-btn color="success" @click="openUser(item.ID)">Show</v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import {API_BASE_URL, API_HELIUM_URL } from '../../constants';
import moment from "moment";

export default {
  setup() {},
  data() {
    return {
      items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
      message: "",
      amount: 0,
      nowDate: new Date().toISOString().slice(0, 10),
      mindat: '',
      dialog: false,
      hotspotDetails: [],
      hotspotTotal: [],
      progressBar: false,
      SelectedUser:"",
      url: "",
      mapurl: "",
      start: "",
      today: "",
      search: "",
      allUser:[],
      uid: localStorage.getItem('user-portal-uid'),
      menu2: false,
      menu1:false,
      date2:"",
      date1:"",
      menu3: false,
      menu4:false,
      date3: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      date4: moment().format('YYYY-MM-DD'),
      accountAdded: [],
      oracleprice: null,
      installation: "",
      requestLog: [],
      requestLog2: [],
      requestLog3: [],
      maxearn: 0,
      indexcount:[],
      allHotSpots: [],
      hotspotAll: [],
      commission: 20,
      first30days: 0,
      signneed: false,
      installdatetime: "",
    };
  },
  computed: {
    headers() {
      return [
        { text: "Hotspot Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Location", value: "location" },
        { text: "Status", value: "status" },
        //{ text: "Total earning", value: "total" },
      ];
    },
    headers2() {
      return [
        { text: "Status", value: "status" },
        { text: "Hotspot Name", value: "name" },
        { text: "Hotspot Address", value: "address" },
        { text: "Hotspot Location", value: "location" },
        { text: "Total Rewards", value: "total",          filter: value => {
            if (!this.amount) return true;
            return value < this.amount;
          }},
      ];
    },
    headers3() {
      return [
        { text: "Status", value: "status" },
        { text: "Hotspot Name", value: "name" },
        { text: "Hotspot Address", value: "address" },
        { text: "Hotspot Location", value: "location" },
        { text: "Total Rewards for the Hotspot", value: "total" },
        { text: "Host Name’s Relationship to Hotspot", value: "relationship" },
        { text: "Online Date", value: "timestamp" },
      ];
    },
  },

  beforeMount() {
    //this.getRequest();
    this.getglobal();
    this.getOracleValue();
    this.getAllUser()
    this.getAddress();
  },

  methods: {
    getItemText(item) {
    return `${item.name} - ${item.email}`;
    },
    getAllUser(){
      axios
        .get(`${API_BASE_URL}/users`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.allUser = resp.data
          });
    },
    getOracleValue() {
      axios
        .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data;
        });
    },
    sendRequest(amount, message) {
      console.log(this.message);
      axios.post(`${API_BASE_URL}/user/request/${this.uid}`, {
        payment: this.message,
        amount: this.amount,
      });
      this.dialog = false;
      this.getRequest();
    },
    getAddress() {
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.accountAdded = resp.data;
        });
    },
    getTotal(address, install, ind) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${install.slice(
            0,
            10
          )}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          if (resp.data.data) {
            this.requestLog[ind].total = resp.data.data.total;
          } else {
            this.requestLog[ind].total = 0;
          }
        })
        .catch((resp) => {
          this.requestLog[ind].total = 0;
        });
    },
    getuid() {
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          console.log('inside UUId');
          this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
        });
    },
    getOffline() { 
      var accountAdded = []
            axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          accountAdded = resp.data;

                  this.requestLog = [];
                  var x = 0;

                  

                  for (var i = 0; i < accountAdded.length; i++) {

                     var today = new Date();
                      var end = accountAdded[i].removeDate? new Date(accountAdded[i].removeDate.slice(0,10)):today;

                      //compare only date
                      today.setHours(0,0,0,0)

                      console.log("end = ",end)
                     
                      //get hotspot whose are currently assigned(Empty Remove Date means they are currently assigned)
                      if(end >= today){


                       axios
                          .get(
                            `${API_HELIUM_URL}/v1/hotspots/${accountAdded[i].Haddress}`,
                            {
                              headers: {
                                accept: "application/json",
                              },
                            }
                          )
                          .then((element) => {
                               element = element.data.data


                              if (element.status.online == "offline") {
                                var data = {};
                                data.status = element.status.online;
                                data.name = element.name;
                                data.address = element.address;
                                data.location =
                                  element.geocode.short_street +
                                  " - " +
                                  element.geocode.short_state +
                                  " - " +
                                  element.geocode.short_country +
                                  " - " +
                                  element.geocode.short_city;
                                data.user = "no owner";
                                data.mobile = "";
                                data.hostname = "";
                                data.total = 0;
                                this.getTotal(element.address, element.timestamp_added, x);
                                this.getOwnerHotspot(element.address, x);
                                this.requestLog.push(data);
                                x += 1;
                              }
                          });
                  
                      }
                  }
        });
    },
    getOfflineDate(address){
              axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${address}/activity`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${address}/activitycursor=${resp.data.data.cursor}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            var unix_timestamp = resp.data.data[0].time
      var date = new Date(unix_timestamp * 1000);
      console.log(date)
      })
      })
      .catch((resp) => {

      })

    },
    getUserearn() {
      let UserID = localStorage.getItem('user-portal-uid');

    if(!this.date3 || !this.date4){
      return false;
    }

      var hotspotAll=[]
      axios
        .get(`${API_BASE_URL}/user/hotspot/${UserID}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          hotspotAll = resp.data;
        
      this.requestLog3 = [];
      var x = 0;
      for (var i = 0; i < hotspotAll.length; i++) {
                axios
          .get(`${API_HELIUM_URL}/v1/hotspots/${hotspotAll[i].Haddress}`, {
            headers: { accept: "application/json" },
          })
          .then((element) => {
            console.log('element', element);
                var data = {};
                data.status = element.data.data.status.online;
                data.timestamp = element.data.data.status.timestamp;
                data.name = element.data.data.name;
                data.address = element.data.data.address;
                data.location =
                  element.data.data.geocode.short_street +
                  " - " +
                  element.data.data.geocode.short_state +
                  " - " +
                  element.data.data.geocode.short_country +
                  " - " +
                  element.data.data.geocode.short_city;
                data.user = "no owner";
                data.mobile = "";
                data.wallet = element.data.data.owner
                data.hostname = "";
                data.total = 0;
                console.log(resp.data[x])
                if(resp.data[x].role == 0){data.relationship="Primary Owner"}else if(resp.data[x].role ==1){data.relationship="Referral Owner"}else{data.relationship="Watcher"}
                this.getTotalUser(element.data.data.address, this.date3,this.date4, x);
                this.getUserHotspot(element.data.data.address, x);
                this.requestLog3.push(data);
                x += 1;
          });
      }
      });
    },

        getTotalUser(address, dateinst, endinst,ind, commission = 0) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst}&max_time=${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
         this.requestLog3[ind].total =   (resp.data.data.total - ((resp.data.data.total / 100) * commission)) ;
      //   this.requestLog3[ind].total = resp.data.data.total;
        })
        .catch((resp) => {
          this.requestLog3[ind].total = 0;
        });
    },
        async getOwnerHotspotLower(address, ind) {
      var head = {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      };
      axios
        .get(`${API_BASE_URL}/allowner/${address}`, head)
        .then((result) => {
          if (result.data[0]) {
            this.requestLog2[ind].hostname = result.data[0].name;
            this.requestLog2[ind].user = result.data[0].email;
            this.requestLog2[ind].mobile = result.data[0].Mobile;
          } else {
            this.requestLog2[ind].user = "no owner";
          }
        });
    },
    async getOwnerHotspot(address, ind) {
      var head = {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      };
      axios
        .get(`${API_BASE_URL}/allowner/${address}`, head)
        .then((result) => {
          if (result.data[0]) {
            this.requestLog[ind].hostname = result.data[0].name;
            this.requestLog[ind].user = result.data[0].email;
            this.requestLog[ind].mobile = result.data[0].Mobile;
          } else {
            this.requestLog[ind].user = "no owner";
          }
        });
    },
        async getUserHotspot(address, ind) {
      var head = {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      };
      axios
        .get(`${API_BASE_URL}/allowner/${address}`, head)
        .then((result) => {
          if (result.data[0]) {
            this.requestLog3[ind].hostname = result.data[0].name;
            this.requestLog3[ind].user = result.data[0].email;
            this.requestLog3[ind].mobile = result.data[0].Mobile;
          } else {
            this.requestLog3[ind].user = "no owner";
          }
        });
    },
        getLowerthan(HNT,start,end) {
      this.requestLog2 = [];
      var x = 0;
      for (var i = 0; i < this.accountAdded.length; i++) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/accounts/${this.accountAdded[i].address}/hotspots`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            resp.data.data.forEach((element, index) => {
                var data = {};
                data.status = element.status.online;
                data.name = element.name;
                data.address = element.address;
                data.location =
                  element.geocode.short_street +
                  " - " +
                  element.geocode.short_state +
                  " - " +
                  element.geocode.short_country +
                  " - " +
                  element.geocode.short_city;
                data.user = "no owner";
                data.mobile = "";
                data.hostname = "";
                data.total = 0;
                this.getbalance(element.address,start,end,100,x,HNT);
                this.getOwnerHotspotLower(element.address, x);
                this.requestLog2.push(data);
                x += 1;
            });
          });
      }
    },
    hotspotDet(address, start, today) {
      (this.dialog = false),
        axios
          .get(`${API_HELIUM_URL}/v1/hotspots/${address}`, {
            headers: { accept: "application/json" },
          })
          .then((resp) => {
            this.hotspotDetails = resp.data;
            var [year, month, day] = this.hotspotDetails.data.timestamp_added
              .substring(0, 10)
              .split("-");
            this.installation = new Date(year, month, day);
            axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${this.installation
                  .toISOString()              
                  .slice(0, 10)}`,
                {
                  headers: { accept: "application/json" },
                }
              )
              .then((resp) => {
                this.hotspotTotal = resp.data;
                console.log('this.hotspotTotal', resp.data);
                this.mapurl = [
                  "https://www.openstreetmap.org/export/embed.html?bbox=" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "&layer=mapnik&marker=" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng,
                ].join("");
                this.maxearn = parseInt(
                  ((this.hotspotTotal.data.total / 100) * 20).toFixed(2)
                );
              });
          });
    },
    openUser(userid) {
      this.$router.push({ name: "user", params: { id: userid } });
    },
    getbalance(address, dateinst, endinst, commission,ind,value) {
      console.log(address, dateinst, endinst)
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst}&max_time=${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          this.requestLog2[ind].total = (resp.data.data.total / 100) * commission;
          if (this.requestLog2[ind].total <= value){
              this.indexcount.push(ind)
          }
        })
        .catch((resp) => {
          this.requestLog2[ind].total = 0;
        });
    },
    getRequest() {
      axios
        .get(`${API_BASE_URL}/users`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          var requestLog = resp.data;
          requestLog.forEach(
            (obj) => (obj["createDate"] = obj["createDate"].substring(0, 10))
          );
          axios
            .get(`${API_BASE_URL}/usersHotspot`, {
              headers: {
                accept: "application/json",
                "x-access-token": this.token.accessToken,
              },
            })
            .then((resp) => {
              var hotspotOwn = resp.data;
              var i;

              for (i = 0; i < requestLog.length; i++) {
                if (
                  requestLog[i]["createdsign"] == "2021-07-06T18:47:22.000Z"
                ) {
                  requestLog[i]["createdsign"] = "";
                }
                if (requestLog[i]["signed"] == "") {
                  requestLog[i]["signed"] = "No";
                }
                if (requestLog[i]["signed"] == 0) {
                  requestLog[i]["signed"] = "No";
                }
                if (requestLog[i]["signed"] == 1) {
                  requestLog[i]["signed"] = "Yes";
                }
                if (
                  hotspotOwn.find(
                    ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                  )
                ) {
                  const result = hotspotOwn.find(
                    ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                  );
                  requestLog[i].assignHotspot = result.assignHotspot;
                } else {
                  requestLog[i].assignHotspot = 0;
                }
              }
              this.requestLog = requestLog;
            });
        });
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },

    saveHotspot(address) {
      let payload = { haddress: address };
      let res = axios.put(
        `${API_BASE_URL}/hotspot/${this.uid}`,
        payload
      );
      let data = res.data;
    },
    checkfirstdays(role, startdate, firstdays) {
      var [year, month, day] = startdate.substring(0, 10).split("-");
      var installation = new Date();
      if (role == 1) {
        if (firstdays == 0) {
          //30 days NO
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        }
      } else {
        //primary owner
        if (this.first30days == 0) {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        }
      }
      this.installation = installation.toISOString().substring(0, 10);
      return installation.toISOString().substring(0, 10);
    },
    getbalancee(address, dateinst, endinst, commission,firstday, type) {
      var final_comm = 0;
      if (type == "refer") {
        axios
          .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            if (resp.data.length != 0) {
              final_comm = resp.data[0].commission;
            } else {
              final_comm = 20;
            }
            this.getbalanceStep2(
              address,
              dateinst,
              endinst,
              commission,
              final_comm
            );
          });
      } else {
        this.getbalanceStep2(
          address,
          dateinst,
          endinst,
          commission,
          final_comm
        );
      }
    },
    getbalanceStep2(address, dateinst, endinst, commission, final_comm) {
      var totalearn = 0;
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst.slice(
            0,
            10
          )}${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          totalearn = ((resp.data.data.total-(resp.data.data.total / 100) * final_comm))/ 100 * commission;
          for (var i = 0; i < this.requestLog3.length; i++) {
            if (this.requestLog3[i].address == address) {
              this.requestLog3[i].total = totalearn.toFixed(2);
            }
          }
        })
        .catch((resp) => {
          for (var i = 0; i < this.requestLog3.length; i++) {
            if (this.requestLog3[i].address == address) {
              this.requestLog3[i].total = (0).toFixed(2);
            }
          }
        });
    },
    getglobal() {
      axios
        .get(`${API_BASE_URL}/user/info/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            if(resp.data[0].investor == 0){
               if(resp.data[0].signed==1 || resp.data[0].signed==null){this.signneed=false}
              else{this.signneed=true}
                (this.commission = resp.data[0].commission),
                (this.first30days = resp.data[0].FirstDays);
            }
          } catch (err) {
            this.error = true;
          }
        });
    },
  },
  
};
</script>
