<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Jag Main Account
            </div>
            <v-dialog v-model="dialog" max-width="1200px" height="1000px">
              <v-card>
                <v-card-title class="headline blue darken-4 white--text">
                  Manage Addresses
                   <v-spacer></v-spacer>
                 <v-btn icon @click="dialog = false" >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-card-title>
                <v-card-text class="pa-3">
                  <div>
                    <v-text-field
                      v-model="message"
                      :append-outer-icon="message ? 'mdi-plus' : 'mdi-plus'"
                      color="#474DFF"
                      filled
                      clearable
                      label="Enter Address"
                      type="text"
                      @click:append-outer="newAddress(message)"
                    ></v-text-field>
                  </div>
                  <v-data-table
                    :headers="headers2"
                    :items="accountAdded"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:body.append> </template>
                    <template v-slot:item.action="{ item }">
                      <v-btn color="error" @click="delAddress(item.ID)"
                        >Delete
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
             
              </v-card>
            </v-dialog>
            <v-btn
              outlined
              dark
              small
              v-bind="attrs"
              v-on="on"
              @click="dialog = true;showSpinner()"
            >
              <v-icon> mdi-cog-outline </v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col md="3" cols="12">
        
        <v-card class="my-2 border-left-1">
          <div>
            <div>
              <v-card-title class="text-h6"> Account Addresses </v-card-title>
              <v-card-subtitle
                style="font-size: 0.8em"
                v-for="item in accountAdded"
                :key="item.address"
              >
                {{ item.address }}
              </v-card-subtitle>
            </div>
          </div>
        </v-card>

        <v-card class="my-2 border-left-4">
          <div>
            <div>
              <v-card-subtitle class="pb-0"> Wallet Balances </v-card-subtitle>
              <v-card-title class="text-h6 gold_4--text">
                {{ !isNaN(account) ? account.toFixed(2) : '' }} HNT
              </v-card-title>
            </div>
          </div>
        </v-card>

        <v-card class="my-2 border-left-1">
          <div>
            <div>
              <v-card-title class="text-h6"> Total HNT Owed to Hosts </v-card-title>
                
                <v-card-subtitle class="pb-0"> Total HNT Mined by all Hotspots</v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text text-center">
                  {{ allmined.toLocaleString('en-US',{maximumFractionDigits:2}) }}
                </v-card-title>
                
                <v-card-subtitle class="pb-0"> Total HNT Earned by all Hosts</v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text text-center">
                  {{ remainbalance.toLocaleString('en-US',{maximumFractionDigits:2}) }}
                </v-card-title>

                <v-card-subtitle class="pb-0"> Total HNT Withdrawn by Hosts</v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text text-center">
                  {{ alreadypayed.toLocaleString('en-US',{maximumFractionDigits:2}) }}
                </v-card-title>

                

                <v-card-subtitle class="pb-0"> Total HNT Owed to all Hosts</v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text text-center">
                  {{ (remainbalance - alreadypayed).toLocaleString('en-US',{maximumFractionDigits:2}) }}
                </v-card-title>

            </div>
          </div>
        </v-card>

        <v-card class="my-2 border-left-1">
          <div>
            <div>
              <v-card-title class="text-h6"> Total HNT Owed to Investors </v-card-title>
                
                <v-card-subtitle class="pb-0"> Total HNT Invested</v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text text-center">
                  {{ (totalInvest/hntperusd).toLocaleString('en-US',{maximumFractionDigits:2}) }}
                </v-card-title>

                <v-card-subtitle class="pb-0"> Total HNT Paid Out</v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text text-center">
                  {{ (totalWithdra/hntperusd).toLocaleString('en-US',{maximumFractionDigits:2}) }}
                </v-card-title>
                
               

            </div>
          </div>
        </v-card>

        <v-card class="my-2">
          <!--
                    <div class="pa-4 text-center">
                        <v-btn
                                tile
                                dark
                                color="blue darken-4"
                                @click=saveAccount(account.data.address)
                        >
                            <v-icon left>
                                mdi-content-save
                            </v-icon>
                            Save
                        </v-btn>
                    </div>
                    -->
        </v-card>
        <v-card class="my-2 border-left-3">
          <div >
            <v-card-subtitle class="pb-0"> Owned Hotspots </v-card-subtitle>
            <v-card-title class="text-h6 gold_4--text text-center">
              {{ ownedHotspots }}
            </v-card-title>
            <v-card-subtitle class="pb-0"> Online Hotspots </v-card-subtitle>
            <v-card-title class="text-h6 gold_4--text text-center">
              {{ onlineHotspots }}
            </v-card-title>
            <v-card-subtitle class="pb-0"> Offline Hotspots </v-card-subtitle>
            <v-card-title class="text-h6 gold_4--text text-center">
              {{ offlineHotspots }}
            </v-card-title>
          </div>
        </v-card>
        <v-card class="my-2 border-left-2">
          <div class="">
            <v-card-subtitle class="pb-0"> Pending requests </v-card-subtitle>
            <v-card-title class="text-h6 gold_4--text text-center">
              {{ Object.keys(pending).length }}
            </v-card-title>
          </div>
        </v-card>

        

      </v-col>
      <v-col md="9" cols="12" class="my-2">
        <v-card>
          <v-card-title>
            Owned Hotspots
            <vue-json-to-csv 
                :json-data="this.allOwnedHotspots"
              >
                <v-btn fab dark small color="green">
                  <v-icon dark> mdi-microsoft-excel </v-icon>
                </v-btn>
              </vue-json-to-csv>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <div class="pa-2">
         <v-data-table
                :headers="headers"
                :items="allOwnedHotspots"
                :key="name"
                class="elevation-1"
                :search="search"
              >
                <template v-slot:[`item.Hotspotname`]="{ item }">
                  <td>
                    {{ item.Hotspotname.replaceAll("-", " ") }}
                  </td>
                </template>
                <template v-slot:[`item.hotspotStatus`]="{ item }">
                  <td class="capitalize">
                    {{ item.hotspotStatus }}
                  </td>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn small color="success" @click="openHotspot(item.hotspotAddress)"
                    >show
                  </v-btn>
                </template>
              </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import firebase from "firebase/app";
import { mapGetters } from "vuex";
import {API_BASE_URL, API_HELIUM_URL, API_HELIUM_NODE_JAG_URL, HOTSPOTTY_BASE_URL, HOTSPOTTY_TOKEN } from '../constants';

export default {
  setup() {},
  data() {
    return {
      dialog: false,
      account: 0,
      search: "",
      calories: "",
      ownedHotspots: 0,
      onlineHotspots:0,
      offlineHotspots:0,
      hotspotaccount: [],
      props: ["uid"],
      test: [],
      address: "",
      finalexport: [],
      uid: "",
      arr: [],
      hotspotAll: {},
      message: "",
      pending: "",
      token: JSON.parse(localStorage.getItem("user")),
      accountAdded: [],
      snackbar: false,
      text: "Save Successful",
      timeout: 4000,
      attrs: '',
      on: '',
      name: '',
      remainbalance: 0,
      allmined: 0,
      alreadypayed:0,
      totalInvest:0,
      investAllLog:[],
      withdraAllLog:[],
      totalWithdra:0,
      oracleprice:null,
      hntperusd:0,
      info:{
       "commission":20 /*added for temporary purpose*/
      },
       allOwnedHotspots: [],
      users: []
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
      headers() {
      return [
        {
          text: "Hotspot Name",
          align: "start",
          value: "hotspotName",
        },
        {
          text: "Status",
          value: "hotspotStatus",
        },
        { text: "Location", value: "hotspotLocation" },
        { text: "Owner", value: "hotspotOwnerEmail" },
        { text: "Return", value: "hotspotReturn" },
        { text: "Actions", value: "action", sortable: false, align: "center" },
      ];
    },
    headers2() {
      return [
        { text: "Address", value: "address" },
        { text: "Creation DateTime", value: "entered" },
        { text: "Actions", value: "action", sortable: false, align: "right" },
      ];
    },
  },

  beforeMount() {
     var ld = this.$root;
     ld.isLoading =   true;

      setTimeout(function(){
        ld.isLoading = false;
      },10000);
   
    // To get HNT to USD conversion
    // this.getOracleValue();
    
    // To get mined and earned HNT
    // this.getTotalMinedAndEarnedHNT();

    this.getUserHotspots();

    //to calculate all hosts payouts
    this.getRequest();

    // To get all account address
    this.getAddress();

    // To get all pending request
    this.pendingRequest();
    
    //get all investors
    this.getAllInvest();

    //get all withdrwals
    this.getAllWithdraw();

    // this.$root.isLoading = false;
    //this.getHotspotName();
    //this.getuid();
  },
  methods: {
    
    // getOracleValue() {


    //     axios.get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
    //         headers: {
    //             'accept': 'application/json'
    //         },
    //     }).then((resp) => {
    //         this.oracleprice = resp.data;
    //         this.hntperusd = (this.oracleprice.data.price/100000000).toFixed(2)
    //         console.log("oracleprice = ",this.hntperusd)
    //     })
    // },
    openHotspot(hname) {
      this.$router.push({ name: "hotspot", params: { id: hname } });
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().replace('-',' ').indexOf(search) !== -1
      );
    },
    pendingRequest() {
      axios
        .get(`${API_BASE_URL}/requestnumber`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.pending = resp.data;
        });
    },

    handleSuccess() {},
    accountInfo() {

      this.account = 0;
      let addresses = [];
      this.hotspotaccount = [];
      let hotspot = [];
      let ownhotspot = [];

      this.accountAdded.forEach((element) => {
        this.dialog = false;
        addresses.push(
          axios.get(`${API_HELIUM_URL}/v1/accounts/${element.address}`, {
            headers: { accept: "application/json" },
          })
        );
        hotspot.push(
          axios.get(
            `${API_HELIUM_URL}/v1/accounts/${element.address}/hotspots`,
            { headers: { accept: "application/json" } }
          )
        );
      });

      Promise.all(addresses).then((results) => {
        results.forEach((resp) => {
          this.account += resp.data.data.balance / 100000000;
        });

        Promise.all(hotspot).then((results) => {
          results.forEach((resp) => {
            this.hotspotaccount = resp.data.data.concat(this.hotspotaccount);
          });
          this.getOwner();
          //get all investors
          this.getAllInvest();
          //get all withdrwals
          this.getAllWithdraw();

          this.remainpayment();
        });
      });
    },
    getOwner() {
      var emptylist = {
        ID: 0,
        userID: "",
        Haddress: "",
        role: "",
        createAt: "",
        commission: 0,
        firstdays: "0",
        installDate: null,
        removeDate: null,
        signed: "",
        MailingStreet: null,
        MailingCity: null,
        MailingState: null,
        MailingZipCode: null,
        MailingCountry: null,
        name: "",
        email: "",
        firebaseID: "",
        FirstDays: 0,
        createDate: "",
        filepath: "",
        createdsign: "",
      };
      axios
        .get(`${API_BASE_URL}/allowner`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {

          this.onlineHotspots = 0;
          this.offlineHotspots = 0;
          for (let i = 0; i < this.hotspotaccount.length; i++) {

            var trs = resp.data.some(
              (itmInner) => itmInner.Haddress === this.hotspotaccount[i].address
            );
            this.hotspotaccount[i]["Hotspotname"] =
              this.hotspotaccount[i]["name"];
            this.hotspotaccount[i]["status hotspot"] =
              this.hotspotaccount[i]["status"]["online"];

              //count online hotspot
              if(this.hotspotaccount[i]["status"]["online"] == "online"){
                this.onlineHotspots++;
              }else{
                this.offlineHotspots++;
              }

            if (trs) {
              this.finalexport.push({
                ...this.hotspotaccount[i],
                ...resp.data.find(
                  (itmInner) =>
                    itmInner.Haddress === this.hotspotaccount[i].address
                ),
              });
            } else {
              this.finalexport.push({
                ...this.hotspotaccount[i],
                ...emptylist,
              });
              this.test = resp.data;
            }


          }
          console.log('this.finalexport', this.finalexport);
        })
        .catch((resp) => {
          console.log(resp);
        });
    },
    parsingOwners(ownhotspot) {
      Promise.all(ownhotspot)
        .then((results) => {
          results.forEach((resp) => {
            for (let index = 0; index < this.hotspotaccount.length; index++) {
              if (resp.address == this.hotspotaccount[index].Haddress) {
                this.test.push(resp.data);
                //this.hotspotaccount[index] = resp.data.concat(this.hotspotaccount[index]);
              }
            }
          });
        })
        .catch((resp) => {
          console.log(resp);
        });
    },
    formatHotspotName(hotspotName) {
      let hotspot_name = hotspotName.replaceAll('-',' ');
      let hotspot_name_caps = hotspot_name.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      return hotspot_name_caps;
    },
    newAddress(addr) {
      let payload = { address: addr };
      axios
        .post(`${API_BASE_URL}/account`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.snackbar = true;
          // this.accountInfo(addr);
          this.message = "";
          this.getAllOwnedHotspots();
          this.getAddress();

        });
    },

    getAddress() {
      axios
        .get(`${API_BASE_URL}/account`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.accountAdded = resp.data;

          // To get all owned hotspot
          this.getAllOwnedHotspots();
        });
    },

    getUserHotspots(){
      axios
        .get(`${API_BASE_URL}/users/hotspot`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.users = resp.data;
        });
    },
    delAddress(id) {
      axios
        .delete(`${API_BASE_URL}/account/${id}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.snackbar = true;
          this.getAddress();
        });
    },
    getHotspotName() {
    
    },
    getRequest() {

   
      axios
        .get(`${API_BASE_URL}/requests`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.requestLog = resp.data;

          console.log("resp.data",resp)

          for (var i in this.requestLog) {


            if (this.requestLog[i].payment == "HNT") {
              this.requestLog[i].HNTchange = this.requestLog[i].amount;
            }
            if(this.requestLog[i].HNTchange){
              this.alreadypayed += parseFloat(this.requestLog[i].HNTchange);

              //console.log("this.alreadypayed = ",this.alreadypayed," = ",parseFloat(this.requestLog[i].HNTchange)," this.requestLog[i].HNTchange = ",this.requestLog[i].HNTchange)

              if (this.requestLog[i].flag == 0) {
                this.requestLog[i].flag = "Pending";
              } else if (this.requestLog[i].flag == 1) {
                this.requestLog[i].flag = "Completed";
              } else if (this.requestLog[i].flag == 2) {
                this.requestLog[i].flag = "Deleted";
              } else if (this.requestLog[i].flag == 3) {
                this.requestLog[i].flag = "Approved";
              }
            }
            
          }
        });
    },
    saveSignedStatus(status) {
      if(this.signedStatus != null){
      this.signedStatus = status;
      let payload = {
        sign: this.signedStatus,
      };
      axios
        .put(`${API_BASE_URL}/editcontract/${this.uid}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          if (this.signedStatus == 0) {
            this.signedStatus = "NO";
          } else {
            this.signedStatus = "YES";
          }
        });}
    },
    remainpayment() {
  
      this.hotspotAll = this.hotspotaccount;

      this.hotspotAll.forEach((element) => {

            element['role'] = 0;

            var endinst = null;
            var [year, month, day] = element.timestamp_added
              .substring(0, 10)
              .split("-");
            var installation = new Date(year, month - 1, day);
            if (element.installDate != null) {
              this.installdatetime = element.installDate.slice(0, 10);
            } else {
              this.installdatetime = installation.toISOString().substring(0, 10);
            }
            if (element.removeDate != null) {
              endinst = "&max_time=" + element.removeDate.slice(0, 10);
            }
            this.installdatetime = this.checkfirstdays(
              element.role,
              this.installdatetime,
              element.firstdays
            );


            if (element.role == 1) {
              //if refer owner
              if (element.firstdays == 0) {
                this.getbalance(
                  element.address,
                  this.installdatetime,
                  endinst,
                  element.commission,
                  "refer"
                );
              } else {
                this.getbalance(
                  element.address,
                  this.installdatetime,
                  endinst,
                  element.commission,
                  "refer"
                );
              }
            }
            if (element.role == 0) {
              //if primary owner
              if (this.FirstDays == "NO") {
                this.getbalance(
                  element.address,
                  this.installdatetime,
                  endinst,
                  this.info.commission,
                  "primary"
                );
              } else {
                this.getbalance(
                  element.address,
                  this.installdatetime,
                  endinst,
                  this.info.commission,
                  "primary"
                );
              }
            }
      });
    },
    checkfirstdays(role, startdate, firstdays) {
      var [year, month, day] = startdate.substring(0, 10).split("-");
      var installation = new Date();
      if (role == 1) {
        if (firstdays == 0) {
          //30 days NO
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        }
      } else {
        //primary owner
        if (this.FirstDays == "YES") {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        }
      }
      this.installation = installation.toISOString().substring(0, 10);
      return installation.toISOString().substring(0, 10);
    },
    getbalance(address, dateinst, endinst, commission, type) {
        var final_comm = 0;
        axios
          .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {

           

            if (resp.data.length != 0) {
               //find index
              let index = this.finalexport.findIndex((x) => x.address == resp.data[0].Haddress);
              if(index > -1){
                  this.finalexport[index].email = resp.data[0].email;
              }

              final_comm = resp.data[0].commission;
            } else {
              final_comm = 20;
            }
            this.getbalanceStep2(
              address,
              dateinst,
              endinst,
              commission,
              final_comm
            );
          });
      
    },
    getbalanceStep2(address, dateinst, endinst, commission, final_comm) {
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst.slice(
            0,
            10
          )}${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {

           this.remainbalance +=
            ((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission;

          this.allmined += parseInt(resp.data.data.total);


           //console.log( "total",resp.data.data.total,"final comm",final_comm,"commisison",commission,"addr",address);
           //console.log( "allmined",this.allmined,"remainbalance = ",this.remainbalance);

        })
        .catch((resp) => {
          this.remainbalance += 0;
        });
    },
    saveAccount(address) {
      let payload = { address: address };
      let res = axios.put(
        `${API_BASE_URL}/wallet/${this.uid}`,
        payload
      );
      let data = res.data;
    },
    getAllInvest() {
      axios
        .get(`${API_BASE_URL}/investments`, {
          headers: { 
          accept: "application/json" ,
          "x-access-token": this.token.accessToken
          },
        })
        .then((resp) => {
          this.investAllLog = resp.data;

         
          for (var i in this.investAllLog) {
            this.totalInvest += parseInt(this.investAllLog[i].amount);
            
          }

        });
    },
    async getAllOwnedHotspots() {

      let hotspots = await this.getHotspottyWalletHotspotsDetail();

      for(let i = 0; i < hotspots.length; i++){
        let hotspot = hotspots[i];

        if(hotspot){

          //get owner's email
          console.log('this.users', this.users);
          let owner = this.users.findIndex((x) => x.Haddress == hotspot.id && x.role == 0);
          let ownerEmail = 'N/A';
          if(owner > -1){
            ownerEmail = this.users[owner].email;
          }

          let hotspotObj = {};
          hotspotObj.hotspotName = this.formatHotspotName(hotspot.name);
          hotspotObj.hotspotStatus = hotspot.active ? 'Online' : 'Offline';
          hotspotObj.hotspotLocation = hotspot.place;
          hotspotObj.place = hotspot.place;
          hotspotObj.hotspotOwnerEmail =  ownerEmail;
          hotspotObj.hotspotOwnerId = hotspot.owner;
          hotspotObj.hotspotReturn = '';
          hotspotObj.hotspotAddress = hotspot.id;

          this.allOwnedHotspots.push(hotspotObj);
        }

        this.loading = false;
      }

      // axios.get(`${API_HELIUM_NODE_JAG_URL}/v1/all/owned/hotspot`, {
      //   headers: {
      //     accept: "application/json",
      //     "x-access-token": this.token.accessToken,
      //   },
      // })
      // .then((resp) => {
      //   let allOwnedHotspots = resp.data.allOwnedHotspot;
      //   if(allOwnedHotspots.length > 0) {
      //     this.ownedHotspots = allOwnedHotspots.length;
      //     console.log(allOwnedHotspots);
      //     allOwnedHotspots.forEach((element, index) => {
      //       let hotspotObj = {};

      //       if(element.hotspotStatus === 'online') {
      //         this.onlineHotspots++;
      //       }
      //       else {
      //         this.offlineHotspots++;
      //       }

      //       hotspotObj.hotspotName = typeof(element.hotspotName) !== 'undefined' && element.hotspotName !== undefined?this.formatHotspotName(element.hotspotName):"NA";
      //       hotspotObj.hotspotStatus = element.hotspotStatus;
      //       hotspotObj.hotspotLocation = (element.hotspotLocation) ? element.hotspotLocation : 'N/A';
      //       hotspotObj.hotspotAddress = element.hotspotAddress;
      //       hotspotObj.hotspotOwnerEmail = (element.hotspotOwnerEmail) ? element.hotspotOwnerEmail : 'N/A';
      //       hotspotObj.hotspotOwnerId = element.hotspotOwnerId;
      //       hotspotObj.hotspotReturn = (element.hotspotReturn) ? element.hotspotReturn.toFixed(2) : 0.00;

      //       this.allOwnedHotspots.push(hotspotObj);
      //     });
      //   }
      //   this.loading = false;
      // });
    },
    async getHotspottyWalletHotspotsDetail(){
      let walletsArray = [];
      for(let i = 0; i < this.accountAdded.length; i++){
        walletsArray = [...walletsArray, this.accountAdded[i].address];
      }
      return new Promise (function( resolve ) {
        axios.post(`${HOTSPOTTY_BASE_URL}/wallets/hotspots`,{
          owner: walletsArray
        }, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          res = res.data.data;
          resolve(res);
        })
        .catch(() => {
          resolve({});
        })
      })
    },
    async getHotspottyHotspot(hotspotAddress){
      return new Promise (function( resolve ) {
        axios.get(`${HOTSPOTTY_BASE_URL}/hotspots/${hotspotAddress}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          res = res.data.data;
          resolve(res);
        })
        .catch(() => {
          resolve({});
        })
      })
    },
    getHotspottyWalletHotspots(wallet){
      return new Promise (function( resolve, reject ) {
        axios.get(`${HOTSPOTTY_BASE_URL}/wallets/${wallet}/hotspots`, {
          headers: {
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          resolve(res.data.data.hotspots);
        }).catch((err) => {
          resolve([]);
        })
      })
    },
    getWalletBalance() {
      axios.get(`${API_HELIUM_NODE_JAG_URL}/v1/accounts/total`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        this.account = (resp.data.walletbalance) ? resp.data.walletbalance.toFixed(2) : 0.00;
      });
    },
    getTotalMinedAndEarnedHNT() {
      axios.get(`${API_HELIUM_NODE_JAG_URL}/v1/users/hotspot/earning`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        this.allmined = (resp.data[0].totalMining) ? resp.data[0].totalMining : 0;
        this.remainbalance = resp.data[0].totalEarning;
      });
    },
    getAllWithdraw() {
      axios
        .get(`${API_BASE_URL}/withdrawal`, {
          headers: { 
          accept: "application/json" ,
          "x-access-token": this.token.accessToken
          },
        })
        .then((resp) => {
          this.withdraAllLog = resp.data;

          for (var i in this.withdraAllLog) {
            this.totalWithdra += parseInt(this.withdraAllLog[i].amount);
          }

        });
    },
    showSpinner(){
      this.$root.isLoading = true;
      setTimeout(()=>{
        this.$root.isLoading = false;
      },1500);
    },
  },
};


</script>
<style>
.capitalize {
    text-transform: capitalize;
}
.style-1 {
  background-color: #ff5252;
}
.style-2 {
  background-color: rgb(255, 255, 255);
}

.v-card__title {
  word-break:break-word !important;
}
</style>