<template>
  <div class="light">
    <v-container>
      <v-col v-if="loading === false" cols="12">
        <v-alert type="error"> DASHBOARD IS LOADING </v-alert></v-col
      >
      <v-col cols="12">
        <v-card class="pa-3 purple darken-1">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Hotspot Dashboard
            </div>
            <v-spacer></v-spacer>
            <div
              class="text-sm-h5 text-xs-h6 font-weight-bold white--text"
              style="text-transform: capitalize"
            >
              <v-btn
                fab
                dark
                small
                color="green"
                v-if="status == 'online'"
              ></v-btn>
              <v-btn
                fab
                dark
                small
                color="red"
                v-if="status == 'offline'"
              ></v-btn>
              {{ status }}
            </div>
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              <v-spacer></v-spacer>
              {{ "  " }}
            </div>
            <div
              class="text-sm-h5 text-xs-h6 font-weight-bold white--text mx-2"
            >
              <v-autocomplete
                dark
                :items="hots"
                label="Leased Hotspot"
                dense
                v-model="hname"
                @input="getHotspot(hname)"
              ></v-autocomplete>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="status == 'offline'" cols="12">
        <v-alert type="error" v-on:click="showTaC">
          A hotspot is offline. Click here for suggestions on how to
          troubleshoot.
        </v-alert></v-col
      >
      <v-dialog v-model="signneed" persistent max-width="450">
        <v-card>
          <v-card-title class="text-h5">
            New contract awaiting signature.
          </v-card-title>
          <v-card-text
            >A new hotspot has been assigned to you. Please sign the contract to
            see the hotspot details.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--<v-btn color="purple darken-1" text @click="signneed = false">
              Close
            </v-btn>-->
            <v-btn color="purple darken-1" text to="/sign"> Go to sign </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col v-if="notamonth === false" cols="12">
        <v-alert type="warning">
          You will start earning rewards on {{ hname }} (30 days after install)
        </v-alert></v-col
      >
      <v-col v-if="daterror === false" cols="12">
        <v-alert type="error">
          Start and End date can not be same. To View 1 day date , please select
          end as next date
          <h1></h1> </v-alert
      ></v-col>
      <v-col v-if="datareverse === false" cols="12">
        <v-alert type="error">
          Enter a valid data range
          <h1></h1> </v-alert
      ></v-col>
      <v-row md="6" cols="12">
        <v-col md="5" cols="12">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Start Date"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              :min="this.installation"
              :max="this.todaydate.toISOString().slice(0, 10)"
              @input="menu1 = false"
            ></v-date-picker> </v-menu
        ></v-col>
        <v-col md="5" cols="12">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date2"
                label="End Date"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date2"
              no-title
              :max="this.todaydate.toISOString().slice(0, 10)"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" cols="12">
          <v-btn
            tile
            dark
            color="purple darken-1"
            @click="
              filtereward(haddress, installation, date, date2, commission)
            "
          >
            <v-icon left> mdi-database-search </v-icon>
            filter data
          </v-btn>
        </v-col></v-row
      >
      <v-row class="my-4">
        <v-col md="6" cols="12">
          <div class="d-flex align-center">
            <v-icon class="mr-1">mdi-chart-box</v-icon>
            30 Day Reward
          </div>

          <v-card class="my-2 pa-4">
            <apexchart
              type="bar"
              height="350"
              :options="chartOptions1"
              :series="series"
            ></apexchart>
          </v-card>
          <v-row>
            <v-col lg="3" md="6" cols="12" class="pa-1">
              <v-card color="purple darken-1" dark class="text-center">
                <v-card-text>
                  <div class="subtitle-2">Today</div>
                  <div class="subtitle-1">
                    <b class="white--text">{{ today }}</b>
                    <span class="subtitle-2">HNT</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="3" md="6" cols="12" class="pa-1">
              <v-card color="purple darken-1" dark class="text-center">
                <v-card-text>
                  <div class="subtitle-2">Yesterday</div>
                  <div class="subtitle-1">
                    <b class="white--text">{{ yesterday }}</b>
                    <span class="subtitle-2">HNT</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="3" md="6" cols="12" class="pa-1">
              <v-card color="purple darken-1" dark class="text-center">
                <v-card-text>
                  <div class="subtitle-2">7 days earn</div>
                  <div class="subtitle-1">
                    <b class="white--text">{{ week }}</b>
                    <span class="subtitle-2">HNT</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="3" md="6" cols="12" class="pa-1">
              <v-card color="purple darken-1" dark class="text-center">
                <v-card-text>
                  <div class="subtitle-2">30 day earn</div>
                  <div class="subtitle-1">
                    <b class="white--text">{{ month }}</b>
                    <span class="subtitle-2">HNT</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6" cols="12">
          <div class="d-flex align-center">
            <v-icon class="mr-1">mdi-currency-usd-circle-outline</v-icon>
            Wallet Rewards
          </div>
          <v-card class="my-2 pa-4">
            <div class="d-flex justify-space-between">
              <div class="text-h5">
                <v-icon class="mr-1" large
                  >mdi-currency-usd-circle-outline</v-icon
                >
                Start Earning Date {{ installation }}
              </div>
              <div class="text-h5">
                <v-icon class="mr-1" large
                  >mdi-currency-usd-circle-outline</v-icon
                >
                {{ sums.toFixed(2) }} HNT - ${{
                  (sums * (oracleprice / 100000000)).toFixed(2)
                }}
              </div>
            </div>
            <div class="text-right mb-3">
              <small>{{ haddress }}</small>
            </div>
            <apexchart
              ref="realtimeChart"
              type="area"
              height="350"
              :options="chartOptions1"
              :series="series2"
            ></apexchart>
          </v-card>
        </v-col>
        <v-col md="6" cols="12"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import { API_BASE_URL, API_HELIUM_URL } from "../../constants";

export default {
  data() {
    return {
      uid: localStorage.getItem('user-portal-uid'),
      haddress: "",
      oracleprice: "",
      sums: 0,
      yesterday: 0,
      loading: false,
      menu1: false,
      menu2: false,
      signneed: false,
      date: "",
      status: "not available",
      daterror: true,
      datareverse: true,
      date2: "",
      month: 0,
      week: 0,
      arr: {},
      todaydate: new Date(),
      hotspotTotal: [],
      installation: 0,
      notamonth: false,
      hname: "",
      today: 0,
      first30days: 0,
      commission: 20,
      hots: [],
      chartOptions1: {
        chart: {
          height: 450,
          type: "bar",
          reset: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },

        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
        },
        xaxis: {
          labels: {
            rotate: 0,
          },
          categories: [],
          tickPlacement: "on",
          tickAmount: 15,
        },
        yaxis: {
          title: {
            text: "Reward",
          },
          tickAmount: 10,
        },
        colors: ["#8e24aa"],
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            opacityFrom: 0.7,
            opacityTo: 0.5,
          },
        },
      },
      chartOptions2: {
        chart: {
          height: 450,
          type: "area",
          reset: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },
        markers: {
          size: 3,
          hover: {
            size: 5,
          },
        },

        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          labels: {
            rotate: 0,
          },
          categories: [],

          tickPlacement: "on",
        },
        yaxis: {
          title: {
            text: "Reward",
          },
          tickAmount: 10,
        },
        colors: ["#8e24aa"],
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          },
        },
      },
      series: [
        {
          name: "HNT",
          data: [], //this.$cookies.get('series')
        },
      ],
      series2: [
        {
          name: "HNT",
          data: [], //this.$cookies.get('series')
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  beforeMount() {
    this.getuid(),
      this.getOracleValue(),
      this.getHotspotName(),
      (this.loading = true);
  },

  methods: {
    getMonthcalc(haddress, installation, end, commission,final_comm) {
      var today = new Date();
      var ends = new Date();
      ends.setDate(today.getDate() - end);
      if (ends >= installation) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${ends
              .toISOString()
              .slice(0, 10)}&max_time=${today.toISOString().slice(0, 10)}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            this.month = (((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission).toFixed(2);
          })
          .catch((err) => {
            this.month = (0).toFixed(2);
          });
      }
    },
    showTaC() {
      var pdf = `${API_BASE_URL}/gethelp`;
      window.open(pdf);
    },
    filtereward(haddress, installation, date, date2, commission) {
      if (date == date2) {
        this.daterror = false;
      }
      if (date >= date2) {
        this.datareverse = false;
      } else {
        var aa = new Date(date2);
        this.daterror = true;
        this.datareverse = true;
        aa.setDate(aa.getDate() + 1);
        this.series = [
          {
            name: "HNT",
            data: [],
          },
        ];
        this.series2 = [
          {
            name: "HNT",
            data: [],
          },
        ];
        this.chartOptions2.xaxis.categories = [];
        this.chartOptions1.xaxis.categories = [];
        var arr = [];
        var earndate = [];

        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${date}&max_time=${aa
              .toISOString()
              .slice(0, 10)}&bucket=day`,
            {
              headers: { accept: "application/json" },
            }
          )
          .then((resp) => {
            this.accountActivity(
              haddress,
              date,
              aa.toISOString().slice(0, 10),
              commission
            );
            var res = resp.data.data;
            //this.haddress = res
            var total;
            var i;
            for (i = 1; i < res.length; i++) {
              //.timestamp.toISOString().slice(0,10)
              earndate.push(res[i].timestamp.slice(0, 10));
              var mini = res[i].timestamp.substring(0, 10);
              if (installation <= mini) {
                if (this.first30days == 0) {
                  this.notamonth = true;
                }
                total = ((res[i].total / 100) * commission).toFixed(4);
                arr.push(total);
              } else {
                if (this.first30days == 0) {
                  this.notamonth = false;
                }
                arr.push(parseInt(0));
              }
            }
            var testdate = earndate.reverse();
            var testearn = arr.reverse();
            this.series = [
              {
                name: "HNT",
                data: testearn,
              },
            ];
            this.series2 = [
              {
                name: "HNT",
                data: testearn,
              },
            ];
            this.series[0].data.reverse();
            this.series2[0].data.reverse();
            this.chartOptions1 = {
              ...this.chartOptions,
              ...{
                xaxis: {
                  categories: testdate,
                  labels: {
                    rotate: 0,
                  },
                  tickPlacement: "on",
                },
              },
            };
          })
          .catch((err) => {
            this.series[0].data.push(0);
          });
      }
    },

    populateChartData(haddress, installation, commission,final_comm) {
      this.series = [
        {
          name: "HNT",
          data: [],
        },
      ];
      this.series2 = [
        {
          name: "HNT",
          data: [],
        },
      ];
      this.chartOptions2.xaxis.categories = [];
      this.chartOptions1.xaxis.categories = [];
      var arr = [];
      var earndate = [];
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=-31%20day&bucket=day`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          var res = resp.data.data;
          //this.haddress = res
          var i;
          console.log(res.length);
          for (i = 0; i < res.length; i++) {
            //.timestamp.toISOString().slice(0,10)
            earndate.push(res[i].timestamp.slice(0, 10).replaceAll("-", "/"));
            var [year, month, day] = res[i].timestamp
              .substring(0, 10)
              .split("-");
            var date = new Date(year, month, day);
            month = date.getMonth() - 1;
            date = new Date(year, month, day);
            if (installation <= date) {
              this.notamonth = true;
              arr.push((((res[i].total -
              (res[i].total / 100) * final_comm) /
              100) *
            commission).toFixed(4));
            } else {
              if (this.first30days == 0) {
                this.notamonth = false;
              }

              arr.push(parseInt(0));
            }
          }
          var testdate = earndate.reverse();
          var testearn = arr.reverse();
          this.series = [
            {
              name: "HNT",
              data: testearn,
            },
          ];
          this.series2 = [
            {
              name: "HNT",
              data: testearn,
            },
          ];
          this.chartOptions1 = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: testdate,
                labels: {
                  rotate: 0,
                },
                tickPlacement: "on",
              },
            },
          };
          this.series[0].data.reverse();
          this.series2[0].data.reverse();
        })
        .catch((err) => {
          this.series[0].data.push(0);
        });
    },
    accountActivity(address, start, today, commission) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?max_time=${today}&min_time=${start}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.sums = (resp.data.data.total / 100) * commission;
        });
    },
    getHotspotName() {
      var i;
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.hotspotAll = resp.data;
          for (i = 0; i < this.hotspotAll.length; i++) {
            axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/${this.hotspotAll[i].Haddress}`,
                {
                  headers: {
                    accept: "application/json",
                  },
                }
              )
              .then((resp) => {
                this.hots.push(resp.data.data.name);
              });
          }
        });
    },

    getOracleValue() {
      axios
        .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data.data.price;
        });
    },

    getYesterday(haddress, installation, end, commission,final_comm) {
      var today = new Date();
      var ends = new Date();
      ends.setDate(today.getDate() - end);
      if (ends >= installation) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${ends
              .toISOString()
              .slice(0, 10)}&max_time=${today.toISOString().slice(0, 10)}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            this.yesterday = (((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission).toFixed(2);
          })
          .catch((err) => {
            this.yesterday = 0;
          });
      }
    },

    getWeek(haddress, installation, end, commission,final_comm) {
      var today = new Date();
      var ends = new Date();
      ends.setDate(today.getDate() - end);
      if (ends >= installation) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${ends
              .toISOString()
              .slice(0, 10)}&max_time=${today.toISOString().slice(0, 10)}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            this.week = (((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission).toFixed(2);
          })
          .catch((err) => {
            this.week = (0).toFixed(2);
          });
      }
    },

    getHotspot(hname) {
      this.sums = 0;
      axios
        .get(`${API_HELIUM_URL}/v1/hotspots/name/${hname}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.haddress = resp.data.data[0].address;
          this.status = resp.data.data[0].status.online;
          var haddress1 = resp.data.data[0].address;
          var [year, month, day] = resp.data.data[0].timestamp_added
            .substring(0, 10)
            .split("-");
          axios
            .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
              headers: {
                accept: "application/json",
              },
            })
            .then((resp) => {
              let prom = resp.data;
              var installdatetime;
              this.getglobal();
              prom.forEach((element) => {
                if (element.Haddress == haddress1) {
                  var endinst = null;
                  var installdatetime = "";
                  var installation = new Date(year, month - 1, day);
                  if (element.installDate != null) {
                    installdatetime = element.installDate.slice(0, 10);
                  } else {
                    installdatetime = installation.toISOString().slice(0, 10);
                  }
                  if (element.removeDate != null) {
                    endinst = "&max_time=" + element.removeDate.slice(0, 10);
                  }
                  this.installdatetime = this.checkfirstdays(
                    element.role,
                    installdatetime,
                    element.firstdays
                  );
                  if (element.role == 1) {
                    //if refer owner
                    if (element.firstdays == 0) {
                      this.commission = element.commission;
                      this.getbalance(
                        element.Haddress,
                        this.installdatetime,
                        endinst,
                        element.commission,
                        "refer"
                      );
                    } else {
                      this.commission = element.commission;
                      this.getbalance(
                        element.Haddress,
                        this.installdatetime,
                        endinst,
                        element.commission,
                        "refer"
                      );
                    }
                  }
                  if (element.role == 0) {
                    //if primary owner
                    if (this.first30days == 1) {
                      this.getbalance(
                        element.Haddress,
                        this.installdatetime,
                        endinst,
                        this.commission,
                        "primary"
                      );
                    } else {
                      this.getbalance(
                        element.Haddress,
                        this.installdatetime,
                        endinst,
                        this.commission,
                        "primary"
                      );
                    }
                  }
                
                var final_comm = 0;
                if (element.role == 1) {
                  axios
                    .get(
                      `${API_BASE_URL}/user/hotspot/own/${element.Haddress}`,
                      {
                        headers: {
                          accept: "application/json",
                        },
                      }
                    )
                    .then((resp) => {
                      if (resp.data.length != 0) {
                        final_comm = resp.data[0].commission;
                      } else {
                        final_comm = 20;
                      }
                      this.runCalc(this.installdatetime, element.Haddress, final_comm);
                    });
                } 
                else {
                  this.runCalc(this.installdatetime, element.Haddress, final_comm);
                }
                }
              });
            });
        });
    },
    runCalc(installdatetime, haddress1, final_comm) {
      var today1 = new Date();
      this.today = 0;
      this.yesterday = 0;
      this.week = 0;
      this.month = 0;
      //this.getTotal(this, haddress1, installdatetime, today1);
      this.getToday(haddress1, installdatetime, this.commission, final_comm);
      this.getMonthcalc(
        haddress1,
        installdatetime,
        30,
        this.commission,
        final_comm
      );
      this.getYesterday(
        haddress1,
        installdatetime,
        1,
        this.commission,
        final_comm
      );
      this.getWeek(haddress1, installdatetime, 7, this.commission, final_comm);
      this.populateChartData(
        haddress1,
        installdatetime,
        this.commission,
        final_comm
      );
    },
    checkfirstdays(role, startdate, firstdays) {
      var [year, month, day] = startdate.substring(0, 10).split("-");
      var installation = new Date();
      if (role == 1) {
        if (firstdays == 0) {
          //30 days NO
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        }
      } else {
        //primary owner
        if (this.first30days == 0) {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        }
      }
      this.installation = installation.toISOString().substring(0, 10);
      return installation;
    },
    getbalance(address, dateinst, endinst, commission, type) {
      var final_comm = 0;
      if (type == "refer") {
        axios
          .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            if (resp.data.length != 0) {
              final_comm = resp.data[0].commission;
            } else {
              final_comm = 20;
            }
            this.getbalanceStep2(
              address,
              dateinst,
              endinst,
              commission,
              final_comm
            );
          });
      } else {
        this.getbalanceStep2(
          address,
          dateinst,
          endinst,
          commission,
          final_comm
        );
      }
    },
    getbalanceStep2(address, dateinst, endinst, commission, final_comm) {
      this.sums = 0;
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst
            .toISOString()
            .slice(0, 10)}${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          this.sums =
            ((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission;
        });
    },
    getToday(haddress, installation, commission,final_comm) {
      var today = new Date();
      if (today >= installation) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${today
              .toISOString()
              .slice(0, 10)}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            this.today =(((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission).toFixed(2);
          })
          .catch((err) => {
            console.log(err), (this.today = 0);
          });
      }
    },
    getglobal() {
      axios
        .get(`${API_BASE_URL}/user/info/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
             if(resp.data[0].investor == 0){
               if(resp.data[0].signed==1 || resp.data[0].signed==null){this.signneed=false}
                else{
                  //this.signneed=true //we are moving this functionality to shopify 
                  }
                (this.commission = resp.data[0].commission),
                  (this.first30days = resp.data[0].FirstDays);
             }
          } catch (err) {
            this.error = true;
            console.log("empty profile");
          }
        });
    },
    getuid() {
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          axios
            .get(`${API_HELIUM_URL}/v1/hotspots/${resp.data[0].Haddress}`, {
              headers: {
                accept: "application/json",
              },
            })
            .then((resp) => {
              this.hname = resp.data.data.name;
              this.getHotspot(this.hname);
            });
        });
    },
  },
};
</script>